/* Module: S */
.quick-links__container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  @include font-smoothing;
}

.quick-links__heading {
  @include sq-flex-box();
  justify-content: space-between;
  align-items: flex-start;
  color: #fff;

  margin-top: 0;
  margin-bottom: 0;

  &-text {
    font-size: pxToRem(26);
    line-height: 1.2;
    font-weight: bold;
    color: #fff;

    &:after {
      display: none !important;
    }
  }

  a.quick-links__heading-text {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.quick-links__heading--toggle {
  position: relative;
  color: inherit;
  text-align: inherit;
  font-weight: inherit;
  line-height: pxToRem(30);
  display: block;
  border: 0;
  background: 0;
  margin-left: pxToRem(20);
  height: pxToRem(30);
  width: pxToRem(25);

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: pxToRem(-10.5);
    display: block;
    background-image: url("{{file_dest}}/icon-arrow-down--white.svg");
    background-repeat: no-repeat;
    height: pxToRem(21);
    width: pxToRem(18);
    transform: rotate(0);

    .active & {
      transform: rotate(180deg);
    }
  }
}

.quick-links__column {
  flex-basis: 100%;
  padding: pxToRem(25) 0;
  border-bottom: pxToRem(1) solid rgba(255, 255, 255, 0.5);

  &:first-child {
    padding-top: 0;
  }
}

.quick-links__list {
  @extend %list-reset;
  display: none;
  margin-top: pxToRem(16);

  .active & {
    display: block;
  }
}

.quick-links__item {
  border-bottom: pxToRem(1) solid rgba(255, 255, 255, 0.5);

  &:last-child {
    border-bottom: 0;
    a {
      color: $tomato-light;
      font-weight: bold;
      &:after {
        background-image: url("{{file_dest}}/icon-arrow-right--orange--light.svg");
      }
    }
  }
}

.quick-links__link {
  position: relative;
  font-size: 0.875rem;
  color: #fff;
  display: block;
  text-decoration: none;
  padding: pxToRem(10) 1.75rem pxToRem(10) 0;

  &:hover,
  &:focus {
    text-decoration: underline;
    //color: #eb5830;
  }

  &:after {
    content: "";
    background-image: url("{{file_dest}}/icon-arrow-right.svg");
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: pxToRem(-6.5);
    display: block;
    height: pxToRem(12);
    width: pxToRem(14);
  }
}
