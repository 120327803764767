/* Module: S */
.video-list {

    &__item {
        margin: 0 0 pxToRem(22) 0;
    }

    &--single {
        .video-list__item {
            height: 400px;
        }
    }

    &--double,
    &--triple {
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    &--double {
        .video-list__item {
            width: 48.6%;
            max-height: 320px;
            min-height: 280px;
            height: 20vw;
        }
    }

    &--triple {
        .video-list__item {
            width: 31.5%;
            max-height: 200px;
            height: 20vw;
            min-height: 170px;
        }
    }

}