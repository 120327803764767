/* Module: S */
.quick-links__heading {
  min-height: pxToRem(123);
  &-text {
    font-size: pxToRem(35);
    line-height: 1.14;
  }
}

.quick-links__heading--toggle {
  font-size: pxToRem(35);
  line-height: 1.14;
}
