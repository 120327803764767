/* Module: S */
.page-heading__container {
  padding-top: pxToRem(13);
  padding-bottom: pxToRem(12);

  &--contains-date {
    @include sq-flex-box();
    justify-content: space-between;
    align-items: flex-end;

    .page-heading__heading {
      padding-bottom: 0;
    }

    .page-heading__date {
      min-width: pxToRem(170);
      text-align: right;
    }
  }

  &--contains-search {
    margin-top: 0;
    padding-top: 0;
  }
}

.inside-page__item {
  flex-basis: 33.33%;
}

.inside-page__list {
  margin-bottom: pxToRem(45);
}

.search-info {
  margin-top: pxToRem(20);
}

.detailed-guide {
  &__list {
  }
}

.detailed-guide__wrapper {
  &.detailed-guide__wrapper-2col {
    display: flex;
    .detailed-guide__toc {
      width: 30%;
    }

    .detailed-guide__contents {
      width: 70%;
    }

    .detailed-guide__list .column {
      width: 100% !important;
    }

    tr td {
      min-width: 33%;
    }
    tbody tr td:first-child {
      min-width: 33%;
    }
  }
}

.tag {
  &__link {
    &--clear-filters {
      padding: pxToRem(13) pxToRem(45) pxToRem(12) pxToRem(13);
    }
  }
}

.breadcrumb {
  margin-bottom: pxToRem(10);
  padding: pxToRem(5) pxToRem(350) pxToRem(5) 0;
}

.listen-tool {
  &__wrapper {
    position: absolute;
    right: 0;
    top: pxToRem(-44);
  }

  &--sidebar {
    .listen-tool__wrapper {
      right: pxToRem(188);
    }
  }
}

.page__content {
  .inside-nav {
    &__container {
      background: transparent;

      .uber-accordion__button {
        display: none;
      }
      .accordion__target {
        border: none;
        display: block;
        font-size: pxToRem(16);
        margin: 0 0 pxToRem(5) 0;
        padding: 0;
      }

      .inside-nav__wrapper,
      .detailed-guide__list {
        .inside-nav__column {
          width: 33%;
          float: left;
          padding-right: pxToRem(10);
        }
      }

      .accordion__link {
        display: none;
      }
    }
  }
}

.page__content {
  .inside-nav {
    &__container {

      .accordion__target,
      .accordion__target-active {
        background-color: white;
      }
    }
  }
}

.page__content .inside-nav__container .accordion__link.uber-accordion__button-active + .accordion__target {
  background-color: white;
}

.o-long-doc {
  .page__content {
    order: 0;
  }

  #btn-show-hde-toc {
    display: block;
  }

  #toc-list {
    width: pxToRem(320);
    padding-right: pxToRem(25);
  }
}

.back-to-top {
  padding: 0;
  height: 50px;
  &.fixed .back-to-top__inner {
    margin: 0 pxToRem(32);
  }
}

.back-to-top__inner {
  width: 100%;
  max-width: 1172px;
  margin: 0 auto;
  overflow: hidden;
}

.back-to-top__anchor {
  float: left;
  width: auto;
  padding: 10px 0 0;
  margin: 5px 0;
  transition: background-color 0.3s;
}
