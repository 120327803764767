/* Module: S */
.component {
  + .component {
    margin-top: pxToRem(25);
  }
}

.component__list {
  .component-as-accordion & {
    display: block;
  }

  .datepicker {
    font-size: pxToRem(18);
  }

  table {
    td {
      label {
        font-size: pxToRem(18);
      }
    }
  }
}

.component__heading--toggler {
  font-size: pxToRem(22);
  pointer-events: none;

  &:after {
    content: none;
  }
}

.component__title {
  .filters & {
    font-size: pxToRem(18);
  }
}

.component--toc {
  margin-bottom: pxToRem(30);
  .component__heading {
    background: transparent;
    button {
      padding: pxToRem(10) 0;
      border-bottom: 1px solid #d8d8d8;
      color: #333333;
    }
  }
  .component__list {
    background: transparent;
    padding: 0;
    margin-top: pxToRem(15);
  }
}
