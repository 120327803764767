/* Module: S */
.access-links__heading {
  color: #3a3a3a;
  font-size: pxToRem(28);
  margin-top: 0;
  margin-bottom: 0;
}

.access-links__title {
  font-size: pxToRem(18);
  line-height: 1.61;
}

.access-links__list {
  @extend %list-reset;
}

.access-links__item {
  border-bottom: pxToRem(1) solid #d2d2d2;
}

.access-links__icon {
  margin-right: 1rem;
  flex-shrink: 0;
  width: pxToRem(37);
  padding-top: pxToRem(6);

  &.icon-payment {
    height: pxToRem(36);
    width: pxToRem(36);
    margin-right: pxToRem(11);
  }

  &.icon-calculator {
    height: pxToRem(47);
    width: pxToRem(27);
    margin-right: pxToRem(20);
  }

  &.icon-services {
    height: pxToRem(43);
    width: pxToRem(37);
    margin-right: pxToRem(9);
  }

  &.icon-mypenalty {
    height: pxToRem(42);
    width: pxToRem(30);
    margin-right: pxToRem(16);
  }

  &.icon-partners {
    height: pxToRem(40);
    width: pxToRem(35);
    margin-right: pxToRem(11);
  }

  &.icon-wdo {
    height: pxToRem(45);
    width: pxToRem(33);
    margin-right: pxToRem(13);
  }

  img {
    width: 100%;
    height: auto;
  }
}

.access-links__link {
  color: #000;
  display: flex;
  padding-top: pxToRem(14);
  padding-bottom: pxToRem(14);
  text-decoration: none;

  &:hover,
  &:focus {
    .access-links__title {
      text-decoration: underline;
    }
  }
}

.access-links__title {
  margin-top: 0;
  margin-bottom: 0.2rem;
}

.access-links__description {
  color: #515151;
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 0;
}
