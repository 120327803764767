/* Module: S */
// Public Sans font-family for logo text
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@600&display=swap');

.header {
  position: relative;
  background-color: #fff;
  padding: pxToRem(15) 0;
  z-index: 1000000;

  > .page__wrapper {
    display: flex;
    flex-direction: column;
    
    @media screen and (min-width: 960px) {
      align-items: center;
    }
    
  }
}

.header__magnifier{
  position: relative;
  top: -5px;
}

.logo__container {
  height: pxToRem(50);
  flex-shrink: 0;
}

.logo__link {
  display: inline-block;
}

.logo {
  display: block;
  max-height: pxToRem(50);
  max-width: pxToRem(134);
  height: pxToRem(50);
  width: pxToRem(134);
}

// SUPPORT-452533 - Updating Logo
.logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*justify-content: center;*/
  justify-content: start;
  margin-left: 20px;
  position: relative;
}
.nsw-header__waratah {
  display: flex;
  justify-content: center;
}
.nsw-header__waratah a {
  display: flex;
  text-decoration: none;
  align-items: center;
  width: 42px;
  height: 46px;
}
.nsw-header__name {
  display: none;
}
.nsw-header__title {
  font-family: 'Public Sans', sans-serif;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

@media (min-width: 1024px) {
  .logo-wrapper {
      margin-left: 0px;
  }
  .nsw-header__waratah {
      padding: 0;
  }
  .nsw-header__waratah a {
      width: 72px;
      height: 76px;
  }
  .nsw-header__waratah a:after {
      z-index: 100;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
  }
  .nsw-header__name {
      padding: 0;
      margin-left: 37.6px;
      display: inline-block;
  }
  .nsw-header__title {
      font-size: 1.5rem;
      font-weight: 700;
      color: #002664;
  }
}

.header__actions {
  display: flex;
  position: absolute;
  top: 2rem;
  right: pxToRem(20);

  button + button {
    margin-left: 1.25rem;
  }
}

.nav-search__button {
  padding: 0;
  border: 0;
  padding: 0;
  background: transparent;

  .home & {
    display: none;
  }
}

.nav-search__icon {
  display: block;
  height: pxToRem(20);
  width: pxToRem(20);
}

// dev-rnswdi Design Improvements
.nav-hamburger__button {
  padding: 0;
  border: 0;
  border-radius: 0;
  color: #fff;
  background-color: transparent;
  height: pxToRem(19);
  width: pxToRem(26);

  span {
    display: block;
    height: pxToRem(3);
    background-color: #292929;
    border-radius: pxToRem(2);

    + span {
      margin-top: pxToRem(5);
    }
  }

  &:focus,
  &:hover {
    outline: 0;
  }

  &.nav-expanded {
    span {
      margin-top: 0;

      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        display: none;
      }

      &:last-child {
        transform: rotate(-45deg);
        margin-top: pxToRem(-3);
      }
    }
  }
}

.nav {
  position: relative;

  &.mobile-nav {
    position: fixed;
    padding: 0;
    // width: pxToRem(260);

    // dev-rnswdi Design Improvements
    width: 100%;
    height: 100%;
    background-color: #333;
    top: 0;
    left: 100%;
    z-index: 10;
    overflow: hidden;
    @include font-smoothing;
    &.is-open {
      .mobile-nav__list,
      .search__form {
        transform: translateX(-100%);
      }
    }

    .search__form {
      padding: pxToRem(20) pxToRem(20) pxToRem(10) pxToRem(20);
    }
  }

  &.nav-header {
    order: 2;
    display: none;
  }
}

.nav__list {
  @extend %list-reset;

  .nav-header & {
    display: none;
    border-top: 0.0625rem solid #ddd;
    background-color: #f6f6f6;
    margin: 1rem -1rem -1rem;

    &.active {
      display: block;
    }
  }
}

.nav__link {
  display: block;
  color: #505050;
  font-size: pxToRem(16);
  text-decoration: none;

  .nav-header & {
    padding: 0.75rem 1rem;

    &:focus,
    &:hover {
      background-color: darken(#eee, 5%);
    }
  }
}

.mobile-nav__list {
  @extend %list-reset;
  padding: 0.625rem 1.25rem;
  + .mobile-nav__list {
    margin-top: 1.5rem;
  }
}

.mobile-nav__item {
  // border-bottom: pxToRem(1) solid #bdbdbd;

  .sub-list & {
    border-bottom: 0;
  }
}

.mobile-nav__list,
.search__form {
  transition-duration: 0.3s;
}

.mobile-nav__sub-list {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  padding: 0 1.25rem;
  display: none;
}

.mobile-nav__sub-list ul {
  list-style: none;
  padding: 0;
  li {
    margin-bottom: pxToRem(20);
    a {
      color: white;
      font-size: pxToRem(15);
      text-decoration: none;
      display: block;
      position: relative;
      padding-right: pxToRem(20);
      &:after {
        content: "";
        background-image: url(mysource_files/icon-arrow-right--orange.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        height: 100%;
        width: 14px;
        top: pxToRem(6);
      }
    }
  }
}

.mobile-nav__item {
  &.is-selected {
    .mobile-nav__sub-list {
      display: block;
    }
    .mobile_nav__link-back-container {
      display: flex;
    }
  }
}

.mobile-nav__link {
  position: relative;
  color: #fff;
  font-size: pxToRem(16);
  font-weight: 500;
  line-height: 1.25;
  display: block;
  text-decoration: none;
  padding: pxToRem(18) 0;
  flex-grow: 1;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
  .sub-list & {
    font-size: 0.875rem;
    font-weight: normal;
    padding: 0.75rem 0.75rem 0.75rem 0;

    &:after {
      content: none;
    }
  }
}

.mobile_nav__sub_trigger {
  background-image: url(mysource_files/icon-arrow-right--orange.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: pxToRem(55);
  width: 14px;
  flex-shrink: 0;
  margin: 0 0 0 pxToRem(20);
}

.mobile_nav__link-back-container {
  width: 100%;
  border-bottom: 0.0625rem solid #bdbdbd;
  display: none;
  margin-bottom: pxToRem(25);

  .mobile_nav__sub_trigger {
    transform: rotate(180deg);
    margin: 0 pxToRem(20) 0 0;
  }
}

.mobile_nav__link-container {
  display: flex;
  flex-direction: row;
}

#google-translate__container {
  padding: pxToRem(10) pxToRem(20) 0 pxToRem(20);

  .goog-te-combo {
    width: 100%;
    font-size: pxToRem(12);
    color: #56514b;
    margin-top: 0;
    height: pxToRem(23);
    padding: 0 pxToRem(26) 0 pxToRem(6);
    background: url("mysource_files/icon-select-triangle-down.svg") no-repeat
      center right 8px #fff;
    border: pxToRem(1) solid #d8d8d8;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    -moz-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  .mobile-nav & {
    .goog-logo-link,
    .goog-logo-link:hover,
    .goog-logo-link:visited,
    .goog-te-gadget {
      color: #fff;
    }
  }
}

// dev-rnswdi Design Improvements
.header{
  padding-bottom: 10px;
  
  @media screen and (min-width: $desktop){
    padding-bottom: 0 !important;
  }
}

.mobile-nav__list{
  padding:  0 0 10px 0;
}

.mobile-nav__item-container{
  border: none;
  display: flex;
  align-items: center;
  // padding-right: 35px;
}

.mobile-nav__list .mobile-nav__item{
  border-left: 5px #333333 solid;
}

.mobile-nav__item.active{
  border-left: 5px #C94D31 solid;
  background-color: #262626;
}

.mobile-nav__link{
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  letter-spacing: -0.35px;
  padding: 9px 0 9px 36px;
  margin-top: 14px;
}

.mobile-nav__acc{
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333;
  width: auto;
  height: 100%;  
  margin-right: 40px;
}

.mobile-nav__item.active .mobile-nav__acc{
  background-color: #262626;
}

.mobile-nav__acc-arrow{      
  transform: rotate( 0deg );  
  transition: transform 150ms ease;
  background-color: #333333;
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  margin-top: 15px;
}

.mobile-nav__item.active .mobile-nav__acc-arrow{
  transform: rotate( -180deg );            
  transition: transform 150ms ease;
  background-color: #262626;
}

.mobile-nav__list .mobile-nav__item .mobile-nav__sublist{
  list-style-type: none;
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
  padding: 0 55px;

}

.mobile-nav__item.active .mobile-nav__sublist{
  height: auto;
  padding-bottom: 40px;
}

.mobile-nav__sublist{
  margin-top: 2px;
  margin-right: 2px;
  padding-bottom: 10px;
}

.sub-list .mobile-nav__link{
  font-size: 18px !important;
  border-left: 5px #333333 solid !important;
  line-height: 24.38px;
}

.mobile-nav__sublist.active{
  border-left: 5px #C94D31 solid !important;
  background-color: #262626;
}

.mobile-nav__subitem{
  color: white;
  position: relative;
  padding: 7px 0;
  margin-top: 0;
}

.mobile-nav__sublink{
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.5px;
  letter-spacing: -0.35px;
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
}

.header__menu-main-chevron{
  margin-left: 8px;
}

.mobile-nav__close-menu{
  display: flex;
  flex-wrap: wrap;
  color: white;
  padding: 16px 46px 6px 46px;
  font-size: 20px;
  line-height: 26.82px;
  font-weight: 500;
  letter-spacing: -0.39px;
  background-color: #333;
  border: none;
  margin: 0;
}

.mobile-nav__close-icon{
  margin-right: 20px;
  font-size: 22px;
  letter-spacing: -0.35px;

}

.mobile-nav{
  background-color: #22272B;
}

.search__form{
  padding: 1.25rem 40px 0.625rem 40px !important;
}

// RNSWDI Upgrades
.header__mobile-search-container{
  position: absolute;
  background-color: white;
  width: 100%;
  margin-top: 73px;
  box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.05);
  z-index: 2;

  .search__form{
    padding: 20px 16px !important;
  }

  .search__input-box{
    display: flex;
    align-items: center;
  }

  .search__input{
    padding: 10px 12px !important;
    // padding: 5px 0 5px 8px !important;
    font-size: 1.625rem !important;   
    border: none;
    color: #22272B !important;
  }

  .search__input::placeholder{
    color: rgb(118, 118, 118) !important;
  }

  .search__button{
    width: 30px !important;
    height: auto;
    display: flex;
    align-items: center;

    img{
      width: 100%;
      height: auto;
    }
  }
}

.search-close-icon{
  position: relative;
  top: 2px;
  width: 15px;
}

.nav{
  .search__form{
    @media screen and (min-width: $desktop) {
      display: flex !important;
      margin: 0;
      width: auto !important;
      padding: 0 !important;
      width: 100px;

      @media (min-width: 900px) and (max-width: 1200px) {
        width: 200px;
        margin-left: 10px !important;
      }
    }

    .search__input-box{
      @media screen and (min-width: $desktop) {
        width: 250px;
        margin-left: 20px !important;
      }

      @media (min-width: 900px) and (max-width: 1200px) {
        width: 200px;
        margin-left: 10px !important;
      }
      
    }
    
  }

  .nav__list{
    
    @media screen and (min-width: $desktop) {
      align-items: center;

      .search__form{
        align-items: center;
        height: auto;
        margin-top: 0 !important;
      }
  
      .search__input{
        padding: 5px 0 5px 10px !important;
      }
    }
  }
}

.header__mobile-search{
  background-color: transparent;
  border: 0;
  margin-top: -7px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  min-width: 35px !important;
  min-height: 35px !important;
}

.search-magnifier-icon{
  width: auto;
  min-width: 21px !important;
  min-height: 21px !important;
}

.search-close-icon{
  width: auto;
  min-width: 18px !important;
  min-height: 18px !important;
  position: relative;
  top: -1px;
}

#google-translate__container{
  padding: 0 40px;
  margin-top: 35px;

  @media screen and (min-width: $desktop) {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
    padding: 0 20px!important;
  }
}

.mobile-nav__list.sub-list{
  padding: 0 40px;

  .mobile-nav__item{
    border: 0;
  }
}

.header__menu{
  display: none;
  font-family: $montserrat;
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: $desktop) {
    display: block;
    position: relative;
    padding: 0 1.5rem;
    background-color: $off-grey;
    line-height: 24px;
    color: #22272B;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    padding: 0 2rem !important;
  }
}

.header__menu-main{
  list-style-type: none;
  max-width: 1216px;

  @media screen and (min-width: $desktop) {
    margin: 30px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    justify-content: space-between;
    // justify-content: space-around;
    // justify-content: space-evenly;
    font-size: 1rem;
  }
}

.header__menu-main-items{
  position: relative;
  height: auto;
  border-bottom: 3px #f3f3f3 solid;
}

.header__menu-main-button{
  color: #22272B;
  background-color: #f2f2f2;
  border: none;
  font-size: initial;
  font-weight: 700;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: $desktop) {
    padding: 16px 10px 13px 10px;
  }

  @media screen and (min-width: 1200px) {
    padding: 16px 19px 13px 19px;
  }
}

.header__menu-main-items.active{
  border-bottom: 3px #C94D31 solid;
}

.header__menu-main-chevron{      
  transform: rotate( 0deg );  
  transition: transform 150ms ease;
}

.header__menu-main-chevron.active{
  transform: rotate( -180deg );            
  transition: transform 150ms ease;
}

.header__menu-main-items .header__menu-main-container{
  position: absolute;
  margin: 0 auto;
  margin-top: 3px;
  z-index: 5; 
  
}

.header__menu-main-items:nth-child(2) .header__menu-main-container{

  @media (min-width: 900px) and (max-width: 1200px){
      left: 50% !important;
      transform: translateX(-50%);
  }
  
}

#menu1, #menu2{
  left: 0%;
}

.header__menu-main-items:last-child() .header__menu-main-container{
  right: 0 !important;  
}

.header__menu-main-items:nth-last-child(2) .header__menu-main-container{
  @media screen and (min-width: $desktop) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: 900px) and (max-width: 1200px){
      left: 60% !important;
      transform: translateX(-65%);
  }
  
}

.header__menu-dropdown{
  background-color: white;
  display: flex;
  justify-content: space-around;
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.25);
  padding: 22px 10px !important;
}

.header__menu-dropdown.no-side-content{
  width: auto;
  min-width: 400px !important;
}

.header__menu-dropdown.side-content{
  background-color: white;
  display: flex;
  justify-content: space-around;
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.25);
  padding: 22px 10px !important;

  @media screen and (min-width: $desktop) {
    width: 900px !important;
  }

  @media (min-width: 900px) and (max-width: 1200px){
    width: 700px !important;
  }
}

.header__menu-dropdown-nav{
  margin: 0 auto;
  width: 100%;
  height: auto;

  @media screen and (min-width: $desktop) {
    padding: 0 20px;
  }

  @media (min-width: 900px) and (max-width: 1200px){
    padding: 0 10px;
  }
}

.header__menu-dropdown-navlist{
  list-style-type: none;
  padding-left: 0;
  height: auto;

  h3, li{
    width: 100%;
  }
}

.header__menu-dropdown-bar{
  display: block;
  height: auto;
  display: flex;
  justify-content: center;

  .header__border-bar{
    width: 1px;
    background-color: black;
    height: auto;
  }
}

.header__menu-dropdown-heading{
  padding: 0;
  margin: 0;
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 21.94px;
  letter-spacing: -0.28px;
  width: 100%;

}

.header__menu-dropdown-links{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 47px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: -0.28px;
  text-decoration: none;
  color: #22272B;
  padding: 8px 0 8px 26px;
  width: 100%;

  &:hover{
    background-color: rgba(32, 59, 112, 0.1);
  }

}

.header__menu-dropdown-parent-link{
  font-weight: 700;
  color: #203B70;
}

.header__dropdown-feature{
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  height: auto;
  padding: 0 46px;
  margin: 18px 0;

  @media screen and (min-width: $desktop) {
    padding: 0 46px;
  }

  @media (min-width: 900px) and (max-width: 1200px){
    padding: 0 36px;
  }
}

.header__dropdown-feature-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: start;
  width: 100%;
}

.header__dropdown-image{
  margin: 0 auto;
  // background-image: url("../mysource_files/sydneyharbour.png");
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  object-fit: cover;
  margin-bottom: 27px;

  @media screen and (min-width: $desktop) {
    width: 339px;
    height: 235px;
  }

  @media (min-width: 900px) and (max-width: 1200px){
    width: 339px;
    height: 174px;
  }
}

.header__dropdown-feature-text-container{
  display: grid;
  grid-template-columns: 11fr 1fr;
  justify-items: stretch;
  align-items: center;

  @media screen and (min-width: 1200px){
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
  }

  @media (min-width: 900px) and (max-width: 1200px){
    width: 100%;
    padding: 0 10px;
  }
}

.header__dropdown-feature-heading{
  display: inline-block;
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  letter-spacing: -0.28px;
  color: #203B70;
}

.header__dropdown-feature-link{
  display: flex;
  margin: 0;
  justify-content: flex-end;
  align-items: center;
  transform: translateX(0);
  transition: transform 150ms ease;
  text-decoration: none;
}

.header__dropdown-feature-link.arrow{
  display: flex;
  margin: 0;
  justify-content: flex-end;
  align-items: center;
  transform: translateX(0);
  transition: transform 150ms ease;

  &:hover{
    transform: translateX(5px);
    transition: transform 150ms ease;
  }
}

.header__dropdown-feature-text{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
}
