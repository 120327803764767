
a {
  color: $tomato-dark;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    //color: $hover-red;
  }
}

.h1,
h1 {
  font-size: pxToRem(24);
  line-height: 1.4;
  @media #{$mq-medium} {
    font-size: pxToRem(30);
  }
}

.h2,
h2 {
  font-size: pxToRem(22);
  line-height: 1.28;
  @media #{$mq-medium} {
    font-size: pxToRem(26);
  }
}

.h3,
h3 {
  font-size: pxToRem(20);
  line-height: pxToRem(27);
  margin-bottom: pxToRem(30);
  @media #{$mq-medium} {
    font-size: pxToRem(22);
  }
}

.h4,
h4 {
  font-size: pxToRem(18);
  line-height: pxToRem(22);
}

.h5,
h5 {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.h6,
h6 {
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 0.75rem;
  margin-top: 1.75rem;
}

table {
  width: 99.9%;
  table-layout: fixed;
  hyphens: auto;
  word-break: break-word;
}

th,
td:first-child {
  font-size: pxToRem(14);
  font-weight: 500;
  line-height: 1.43;
  text-align: left;
  color: #fff;
  background-color: #333;
  padding: pxToRem(10) pxToRem(10);
  text-rendering: geometricPrecision;

  @media #{$mq-medium} {
    font-size: pxToRem(16);
    padding: pxToRem(23) pxToRem(22) pxToRem(19);
  }
}

tr {
  td {
    border-left: solid 1px $pinkish-grey;
    color: $dark-grey;
    line-height: 1.43;
    padding: pxToRem(10);
    vertical-align: baseline;

    @media #{$mq-medium} {
      border-left: none;
      color: $off-black;
      min-width: pxToRem(222);
      padding: pxToRem(18) pxToRem(20);

      &:nth-child(odd) {
        background-color: $dolphin-grey;
      }
    }
  }

  th {
    @media #{$mq-medium} {
      border-right: pxToRem(1) solid #fff;
    }

    &:last-child {
      border-right: 0;
    }
  }
}

tbody {
  border: pxToRem(1) solid $pinkish-grey;
  border-top: 0;

  tr {
    border-bottom: pxToRem(1) solid $pinkish-grey;
    border-top: pxToRem(1) solid $pinkish-grey;
    &:last-child {
      border-bottom: 0;
    }

    td:first-child {
      background-color: $dolphin-grey;
      border-right: solid 2px $pinkish-grey;
      color: $dark-grey;
      vertical-align: baseline;

      @media #{$mq-medium} {
        border-right: none;
        color: $black;
        font-size: pxToRem(14);
        min-width: pxToRem(222);
        padding: pxToRem(20) pxToRem(21) pxToRem(18);
      }
    }
  }
}

%dot-styles {
  content: "";
  display: inline-block;
  height: pxToRem(6);
  width: pxToRem(6);
  border-radius: 50%;
  margin-right: 1.25rem;
}

.page__content {
  ol {
    margin-top: pxToRem(12);
    li {
      margin-bottom: pxToRem(12);
    }
  }

  ul:not(.no-content-styles):not(.breadcrumb__list):not(.quick-links__list):not(.feed__list):not(.page-controls__list):not(.access-links__list):not(.slick-dots):not(.tabs__tab-list):not(.inside-page__list):not(.results__list):not(.pagination__list):not(.icon-panels__list):not(.component__list):not(#toc) {
    list-style: none;
    padding-left: 0;

    @media #{$mq-medium} {
      padding-left: pxToRem(40);
    }

    li {
      position: relative;
      margin-top: pxToRem(12);
      margin-bottom: pxToRem(12);
      padding-left: pxToRem(20);

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        position: absolute;
        left: 0;
        top: pxToRem(7);
        background-color: #000;
        @extend %dot-styles;
      }

      > ol {
        
        li {
          padding-left: 0;  
          &:before {
            display: none;
          }
        }
      }

      > ul:not(#toc ul) {
        margin-top: pxToRem(12);
        margin-bottom: pxToRem(12);
        padding-left: 0;

        > li {
          &:before {
            top: pxToRem(8);
            background-color: $tomato-dark;
            @extend %dot-styles;
          }

          > ul > li:before {
            background-color: #000;
            top: pxToRem(9);
            height: pxToRem(2);
            border-radius: 0;
          }
        }
      }
    }
  }

  ol.auto-numbering:not(.no-content-styles) {
    list-style: none;
    padding-left: 0;
    counter-reset: section;

    @media #{$mq-medium} {
      padding-left: pxToRem(40);
    }

    li {
      position: relative;
      // font-weight: 300;
      margin-top: pxToRem(12);
      margin-bottom: pxToRem(12);
      padding-left: pxToRem(20);

      &:before {
        left: 0;
        position: absolute;
        counter-increment: section;
        content: counters(section, ".") ". ";
        color: $tomato-dark;
        font-weight: bold;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      ol {
        margin-top: pxToRem(12);
        margin-bottom: pxToRem(12);
        padding-left: 0;
        counter-reset: section;

        > li {
          padding-left: 2.25rem;
          list-style: none;
          &:before {
            color: #000;
            font-weight: 500;
          }

          ol {
            > li {
              padding-left: 3.2rem;

              &:before {
                color: $tomato-dark;
              }

              ol li {
                padding-left: 3.8rem;
              }
            }
          }
        }
      }
    }
  }
}

%btn-icon-styles {
  content: "";
  display: inline-block;
  margin: pxToRem(-5) pxToRem(10) pxToRem(-5) 0;
  background-repeat: no-repeat;
  left: 1rem;
}

.btn {
  position: relative;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-size: 0.875rem;
  line-height: 1;
  text-decoration: none;
  padding: pxToRem(14) 1rem;
  border: 0;

  &.btn-icon-copy,
  &.btn-icon-download,
  &.btn-icon-print {
    height: pxToRem(42);
  }

  &.btn-icon-print:before {
    @extend %btn-icon-styles;
    width: pxToRem(21);
    height: pxToRem(19);
    background-image: url({{file_dest}}/icon-printer-outline.svg);
  }

  &.btn-icon-copy:before {
    @extend %btn-icon-styles;
    width: pxToRem(21);
    height: pxToRem(19);
    margin-right: pxToRem(5);
    background-image: url({{file_dest}}/icon-copy.svg);
  }

  &.btn-icon-download:before {
    @extend %btn-icon-styles;
    width: pxToRem(19);
    height: pxToRem(22);
    background-image: url({{file_dest}}/icon-download.svg);
  }
}

.btn-results {
  color: #000;
  background-color: #efefef;

  &:focus,
  &:hover {
    background-color: darken(#efefef, 5%);
  }
}
