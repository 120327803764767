/* Module: S */
.results__container {
  margin-top: -1rem;

  &--no-results {
    margin-top: pxToRem(40) !important;
    background: #f5f5f5;
    padding: pxToRem(20);
    border: 1px solid #ddd;
    text-align: center;
  }
}

.results__list {
  @extend %list-reset;
}

.results__item {
  border-bottom: pxToRem(1) solid #d8d8d8;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &--pdf,
  &--word,
  &--xl,
  &--doc {
    .results__title {
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: left 2px;
      padding-left: pxToRem(23);
    }
  }

  &--pdf {
    .results__title {
      background-image: url('../mysource_files/icon-pdf.png');
    }
  }

  &--word {
    .results__title {
      background-size: 16px;
      background-image: url('../mysource_files/icon-word.png');
    }
  }

  &--xl {
    .results__title {
      background-size: 16px;
      background-image: url('../mysource_files/icon-excel.png');
    }
  }

  &--doc {
    .results__title {
      background-image: url('../mysource_files/icon-file.png');
    }
  }

}

.results__link {
  text-decoration: none;

  &:hover,
  &:focus {
    .results__title {
      text-decoration: underline;
    }
  }
}


// .results__category {
//   color: #636363;
//   font-size: 0.875rem;
//   line-height: 1.57;
//   text-transform: uppercase;
//   margin-bottom: pxToRem(5);
// }

//added as part of ticket SDNSW-4077
.results__info {
  display: inline-block;
  font-size: .875rem;
  line-height: 1.57;
  margin-bottom: .3125rem;
  margin-top: .5rem;
  color: #636363;
}


.results__info--category {
  display: inline;

}

.results__info--category > span {
  margin-left: 8px;
  margin-right: 7px; 
}

.results__title {
  font-size: pxToRem(18);
  color: #262626;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0;

  .resources & {
    &:after {
      content: "";
      background-image: url({{file_dest}}/icon-arrow-right--orange.svg);
      background-repeat: no-repeat;
      display: inline-block;
      width: pxToRem(16);
      height: pxToRem(13);
      margin-left: 0.5rem;
      margin-bottom: pxToRem(1);
      vertical-align: middle;
    }
  }
}

// .results__date {
//   color: #000;
//   font-size: 0.875rem;
//   display: block;
//   margin-top: pxToRem(8);
// }

.results__description {
  font-size: 0.875rem;
  color: #262626;
  line-height: 1.57;
  margin-top: 1rem;
  margin-bottom: .5rem;

  strong {
    color: #000;
  }
}

.results__actions {
  display: flex;

  .btn {
    background: #efefef;
    color: #000;
    &:hover {
      background: #ddd;
    }
    + .btn {
      margin-left: 1rem;
    }
  }
}

.icon-panels {
  &__list {
    list-style: none;
    padding: 0;
  }

  &__item {
    list-style: none;
    margin-bottom: pxToRem(20);
    border: 1px solid #979797;
    // &:last-child {
    //   margin-bottom: 0;
    // }
  }

  &__link {
    @include sq-flex-box();
    padding: pxToRem(20);
    text-decoration: none;
    color: #000;
    &:hover {
      .icon-panels__heading {
        //color: #eb5830;
        text-decoration: underline;
      }
    }
    &:after {
      width: pxToRem(30) !important;
      margin-left: auto !important;
    }
  }

  &__icon {
    min-width: pxToRem(32);
    width: pxToRem(32);
    margin-right: pxToRem(15);
    svg {
      width: 100%;
      max-height: pxToRem(40);

      &.icon {
        &.icon-payment {
          height: pxToRem(34);
          width: pxToRem(36);
          margin-right: pxToRem(11);
        }

        &.icon-calculator {
          height: pxToRem(47);
          width: pxToRem(27);
          margin-right: pxToRem(20);
        }

        &.icon-services {
          height: pxToRem(38);
          width: pxToRem(37);
          margin-right: pxToRem(9);
        }

        &.icon-resources {
          height: pxToRem(42);
          width: pxToRem(30);
          margin-right: pxToRem(16);
        }

        &.icon-contact {
          height: pxToRem(40);
          width: pxToRem(35);
          margin-right: pxToRem(11);
        }

        &.icon-consultation {
          height: pxToRem(45);
          width: pxToRem(33);
          margin-right: pxToRem(13);
        }
      }
    }
  }

  .icon-panels__heading {
    margin-top: 0;
    margin-bottom: pxToRem(7);
    font-size: pxToRem(18);
    &:after {
      content: "";
      display: inline-block;
      background: url(mysource_files/icon-arrow-right--orange.svg) no-repeat;
      width: 14px;
      height: 12px;
      margin-left: 7px;
    }
  }

  &__description {
    font-size: 0.875rem;
  }
}

.article {
  &__content {
    color: $off-black;
  }
}