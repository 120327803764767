/* Module: S */
.social-feed {
  @include font-smoothing;
}

.social-feed__heading {
  color: #fff;
  font-size: pxToRem(28);
  margin-top: 0;
  margin-bottom: 0;
}

.social-tabs {
  margin-top: 1.25rem;
}

.feed__icon {
  color: #fff;
  display: inline-block;
  height: pxToRem(31);
  width: pxToRem(31);
  margin-right: 1rem;
  fill: white;

  .uber-accordion__button-active &,
  &.icon-orange {
    color: $tomato-light;
    fill: $tomato-light;
  }
}

.feed__list {
  @extend %list-reset;
}

.feed__item {
  color: #fff;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: pxToRem(1) solid #3d5d85;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.feed__heading-wrapper {
  display: flex;
  align-items: center;
}

.feed__heading {
  color: $tomato-light;
  font-size: pxToRem(19);
  margin-top: 0;
  margin-bottom: 0;

  a {
    text-decoration: none;
    color: $tomato-light;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.feed__description {
  font-size: 0.875rem;
  line-height: 1.57;
  margin-bottom: 0;

  strong {
    a {
      text-decoration: none;
      font-weight: bold;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  a {
    color: #fff;
    font-weight: 500;
  }
}

.feed__video-title {
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.feed__more {
  font-size: pxToRem(14);
  font-weight: bold;
  line-height: 1.57;
  color: #fff;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.social-tabs {
  .tabs__link.uber-accordion__button-active {
    &:before,
    &:after {
      left: 32%;
    }
  }

  .tabs__target {
    padding-bottom: 0;
  }
}
