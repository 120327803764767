/* Module: S */
.search__form {
    display: flex;
    align-items: center;
    padding-top: pxToRem(40);
    padding-bottom: pxToRem(49);

    &.inside {
        padding-top: pxToRem(60);
        padding-bottom: 0;
    }

    .header & {
        display: flex;
        padding: 0;
        margin: pxToRem(4) 0 0 1rem;
        border-top: 0;
        background-color: transparent;
        width: pxToRem(200);

        .search__input {
            border-color: #d8d8d8;
            border-radius: 0;
            color: #505050;
        }

        @include placeholder-color(#757575);

        .home & {
            display: none;
        }
    }
}

.search__heading {
    font-size: pxToRem(28);
    margin-right: 3.875rem;
    margin-bottom: 0;
}

.search__input {
    font-size: pxToRem(18);
    line-height: pxToRem(18);
    padding: pxToRem(21) pxToRem(60) pxToRem(21) pxToRem(21);
}

.search__button {
    margin-top: pxToRem(-14);
    height: pxToRem(28);
    width: pxToRem(28);
}