/* Module: S */
.social-feed__heading { 
    font-size: pxToRem(36);
}

.tabs__target-youtube{
    .feed__list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .feed__item{
            width: 48%;
            &:first-of-type{
            padding-top: 1.25rem;
            }

            &:last-of-type{
            width: 100%;
            }
        }
    }
  } 