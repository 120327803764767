/* Module: S */
.tile__list {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .tile__item:nth-child(-n + 6) {
    display: flex;
  }
}

.tile__item {
  display: flex;
  flex-basis: 50%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex-basis: calc(50% - 1rem);
  }

  &:nth-child(2) {
    margin-top: 0;
  }

  &:nth-child(n + 3) {
    margin-top: 1rem;
  }
}
