/* Module: S */
.results__item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  
  &--doc {
    .results__title {
      padding-right: pxToRem(40);
    }

  }

}

.icon-panels {
  &__list {
    @include sq-flex-box();
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    width: 48%;
    margin-bottom: pxToRem(45);
    min-height: pxToRem(160);
 
  }

  &__link {
    padding: pxToRem(30);
  }
}
