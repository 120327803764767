/* Module: S */
.global-alert {
  background: #ffb62a;
  display: none;
  z-index: 1000000;

  &--enabled {
    display: block;
    margin: 0 auto;
    
  }

  &__container {
    // @include sq-flex-box();
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: $base-page-width;
    // padding: pxToRem(14) pxToRem(16) pxToRem(13) pxToRem(16);
    font-size: 0.875rem;
    color: #000;
  }

  &__icon {
    min-width: pxToRem(33);
    // padding-top: pxToRem(14);
    margin: 0;
    padding-top: 5px;
    align-self: baseline;
  }

  &__btn-close {
    // align-self: flex-start;
    align-self: baseline;
    background: transparent;
    border: none;
    padding: 0;
    // margin-left: auto;
    margin: 0 !important;
    margin-left: 15px !important;
    min-width: pxToRem(22);
    text-align: right;
    img {
      @include sq-transition(0.3s all);
      transform: rotate(0deg);
      position: relative;
      top: -3px;
    }
    &:hover {
      img {
        @include sq-transition(0.3s all);
        transform: rotate(90deg);
      }
    }
  }

  &__message {
    a {
      font-weight: bold;
      color: #000;
    }

    p{
      margin-top: 0 ;
    }
  }
}

// dev-rnswdi Design Improvements
.global-alert{
  padding: 1rem;
}

.global-alert__container{
  display: flex;
  align-items: center;
  // padding: 1rem !important;
  padding: 0 !important;
}

.global-alert__btn-close{
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  margin: auto;
}

.mobile__btn-close{
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 0.5rem;
  padding-right: 1rem;

  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.desktop__btn-close{
display: none;

  @media screen and (min-width: $desktop) {
    display: block;
  }
}