/* Module: S */
.consultations {
  .submissions.accordion {
    margin-top: pxToRem(50);
  }

  .submissions__content {
    @include sq-flex-box();
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .submissions__section {
    width: 33.3%;
    padding-right: 34px;
    margin-bottom: pxToRem(75);
  }

  > .container {
    @include sq-flex-box();
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__link {
    width: 30.5%;
    margin-right: 34px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &.consultations-detail {
    .tabs {
      margin-top: pxToRem(45);
    }

    .consultations__documents-heading,
    .consultations__subheading {
      margin-bottom: 35px;
    }

    .consultations__graph-top {
      @include sq-flex-box();
      justify-content: space-between;
    }

    .consultations__graph-heading {
      margin-top: 0;
      margin-bottom: 0;
    }

    .consultations__graph-bottom {
      @include sq-flex-box();
      justify-content: space-between;
    }
  }

  &--in-progress {
    .consultations {
      &__container {
        @include sq-flex-box;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &__content {
        order: 3;
      }
      &__countdown {
        max-width: 30%;
        order: 2;
      }
      &__graph {
        order: 5;
        width: 100%;
      }
      &__graph-bottom {
        order: 6;
      }
      &__graph-top {
        order: 4;
      }
      &__status {
        @include sq-flex-box;
        justify-content: space-between;
        flex-wrap: wrap;
        order: 7;
        width: 100%;

        p {
          margin-right: pxToRem(15);
          min-width: 25%;

          &:last-of-type() {
            margin-right: 0;
          }
        }
      }
      &__link {
        margin-right: pxToRem(34);
      }
      &__title {
        margin-right: pxToRem(15);
        order: 1;
        width: 65%;
      }
    }
  }
}
