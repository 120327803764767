/* Module: S */
.related-links__container {
  margin-top: pxToRem(60);
  margin-bottom: pxToRem(-60);
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-size: cover;
  position: relative;

  .page__wrapper {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: $theme-black;
  }
}

//themes

body.theme-navy {
  .related-links__container {
    &:before {
      background: $theme-navy;
    }
  }
}

body.theme-plum {
  .related-links__container {
    &:before {
      background: $theme-plum;
    }
  }
}

body.theme-purple {
  .related-links__container {
    &:before {
      background: $theme-purple;
    }
  }
}

body.theme-green {
  .related-links__container {
    &:before {
      background: $theme-green;
    }
  }
}

.related-links__heading {
  color: #fff;
  font-size: pxToRem(24);
  margin-top: 0;
  margin-bottom: 2rem;
}

.related-links__list {
  @extend %list-reset;
}

.related-links__item {
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.related-links__link {
  color: #fff;
  display: block;
  text-decoration: none;

  .related-links__title {
    //text-decoration: underline;
  }

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
    .related-links__title {
      text-decoration: underline;
    }
  }
}

.related-links__title {
  font-size: pxToRem(19);
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.related-links__description {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.57;
  margin-top: 0;
  margin-bottom: 0;
}
