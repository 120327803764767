/* Module: S */
.search__form {
  padding-top: pxToRem(30);
  padding-bottom: pxToRem(34);
  @include font-smoothing;

  &.inside {
    padding-bottom: 0;
  }

  .header & {
    align-items: flex-start;
    padding-top: pxToRem(28);
    padding-bottom: pxToRem(20);

    .search__input {
      border-color: #cacaca;
      border-radius: 6px;
      background-color: transparent;
      color: #fff;
    }

    @include placeholder-color(#fff);
  }

  &.active {
    display: block;
  }
}

.search__heading {
  color: #fff;
  font-size: pxToRem(20);
  margin-top: 0;
  margin-bottom: 0.5rem;
  flex-shrink: 0;
}

.search__input-box {
  position: relative;
  flex-grow: 1;
}

.search__input {
  width: 100%;
  color: #fff;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1rem;
  padding: pxToRem(10) pxToRem(60) pxToRem(10) pxToRem(10);
  border: pxToRem(1) solid #cacaca;
  border-radius: pxToRem(6);
  transition: 0.2s ease-in-out border-color;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
  }

  &:focus {
    outline: 0;
    border-color: #fff;
  }

  .header & {
    font-size: 0.875rem;
    line-height: 1;
    color: #757575;
    background-color: #fff;
    border-color: #d8d8d8;
    border-radius: 0;
    padding: pxToRem(11) pxToRem(34) pxToRem(8) pxToRem(12);

    &:focus {
      border-color: darken(#d8d8d8, 10%);
    }
  }
}

.search__button {
  background-color: transparent;
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: pxToRem(-10);
  display: block;
  border: 0;
  padding: 0;
  height: pxToRem(20);
  width: pxToRem(20);

  .header & {
    height: 1rem;
    width: 1rem;
    margin-top: -0.5rem;
    right: 0.75rem;
  }
}

.search__icon {
  display: block;
  height: 100%;
  width: 100%;
}
