/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";
@import "normalize.scss";

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  min-height: 0;
  min-width: 0;
}

html {
  @include sq-border-box;
}
// == End box-sizing reset ==

@import "utilities.scss";

::-moz-selection {
  background: $content-selection-highlight;
  text-shadow: none;
}

::selection {
  background: $content-selection-highlight;
  text-shadow: none;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: "montserrat", Arial, Helvetica, sans-serif;
  font-size:   pxToRem(13);
  line-height: 1.6;
  padding: 0;
  margin: 0;
  transition: 0.125s transform ease-in-out;

  &.nav-expanded {
    // transform: translate3d(pxToRem(-260), 0, 0);
    
    // dev-rnswdi Design Improvements
    transform: translate3d(-100%, 0, 0);
  }
}

img {
  max-width: 100%;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input {
  &::-ms-clear {
    display: none;
  }
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
  width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
  margin: 0.5cm;
}

@import "content.scss";

.page__wrapper {
  width: 100%;
  max-width: $base-page-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);
}

.page__content {
  flex-grow: 1;
  min-width: pxToRem(1);
  font-size: 0.875rem;
  //   line-height: pxToRem(22);
  + .page__sidebar {
    margin-top: 2rem;
  }
}

.page__sidebar {
  .page-with-filters & {
    order: -1;
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.page__container {
  display: flex;
  flex-direction: column;
}

.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.body-overlay{
  width: 100%;
  height: 0;
  background-color: rgba(0,0,0,.5);
  position: absolute;
  z-index: 100;
  opacity:0;
  transition: opacity .3s ease,height 0s;
}
.nav-expanded .body-overlay{  
  opacity:1;
  height: 100%;
}