/* Module: S */
.search__form .twitter-typeahead {
	width: 100%;
}

.tt-menu {
	border: 1px solid #333;
	width: 100%;
	background-color: #fff;

	.header & {
		border-color: #d8d8d8;
	}
}

.search-suggest-result__container,
.search-suggest-result-large__container,
.search-suggest-result-standard__container {
	display: block;
	border-top: 1px solid #333;
	padding: pxToRem(15) pxToRem(15) 0 pxToRem(15);
	margin: 0 0 pxToRem(15) 0;
	text-align: left;
	text-decoration: none;
	color: #000;

	&:first-child {
		border-top: none;
	}

	&.tt-cursor,
	&:hover,
	&:focus {
		text-decoration: underline;
	}

	.header & {
		border-color: #d8d8d8;
	}
}

.search-suggest-result-standard__container {
	margin: 0;
	font-size: pxToRem(16);
	padding-bottom: pxToRem(15);
	cursor: pointer;

	.header & {
		font-size: pxToRem(14);
	}
}

.search-suggest-result-large__title {
	font-size: pxToRem(16);
	line-height: 1.33;
	margin: 0;
	color: #000;
}

.search-suggest-result-large__summary {
	margin: pxToRem(10) 0 0 0;
	color: #000;
	font-size: pxToRem(16);
	line-height: 1.5;
	display: none;
}