// HTML 5 Boilerplate Print Stylesheet
* {
  background: transparent !important;
  color: #000 !important; // Black prints faster: h5bp.com/s
  box-shadow: none !important;
  text-shadow: none !important;
}

a,
a:visited {
  text-decoration: underline;
}

a[href]:after {
  content: " (" attr(href) ")";
}

abbr[title]:after {
  content: " (" attr(title) ")";
}

// Don't show links for images, or javascript/internal links

.ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
  content: "";
}

pre,
blockquote {
  border: 1px solid #999;
  page-break-inside: avoid;
}

thead {
  display: table-header-group; // h5bp.com/t
}

th,
td,
tr th:last-child {
  border: solid 1px #cacaca;
}

th:first-child {
  border-right: solid 2px #cacaca;
}

tr,
img {
  page-break-inside: avoid;
}

img {
  max-width: 100% !important;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

h2,
h3 {
  page-break-after: avoid;
}

.inpage-utilities,
#btn-show-hde-toc,
.slick-cloned {
  display: none !important;
}

//generic excludes
.landscape__container,
footer,
.listen-tool,
.breadcrumb,
.page-controls__container,
.page__sidebar,
.related-links__container,
.accordion__link:after,
.accordion__controls,
.carousel-controls,
.search__form,
.pagination,
.results__actions,
.tag__list,
.external-link:after,
.search-info,
.consultations__bottoms-actions,
.consultations__form-label,
.consultations__form,
.tabs__item,
.feed__icon,
.feed__more,
.access-links__content:after,
.header__actions,
.nav-header,
#google-translate__container {
  display: none !important;
}

.page__content .inside-nav__container .accordion__link,
.page__content .accordion__link {
  border: 0.0625rem solid #dadada;
  border-bottom: 0;
  margin-bottom: 0;
}

.uber-accordion__target {
  display: block !important;
}

.tabs__tab-content,
.uber-accordion {
  border: none !important;
}

.tabs__target {
  padding: 0;
}

.uber-accordion {
  padding: 0;
}

.inside-nav__container,
.detailed-guide__list {
  display: none;
}

.header,
.carousel__slide,
.inside-nav__container {
  margin-bottom: 20px;
}

.notification-error .svg-icon {
  fill: #000;
}

.carousel {
  display: block !important;
}

.slick-track {
  width: 100% !important;
  transform: none !important;
}

.carousel__caption {
  position: relative;
  padding: 10px 0 10px 0;
}
