body {
  &.nav-expanded {
    overflow-y: inherit;
    transform: none;
  }
}
.page__container {
  flex-direction: row;
}

.page__sidebar {
  flex-basis: pxToRem(305);
  flex-shrink: 0;
  margin-left: pxToRem(45);

  .page__content + & {
    margin-top: 0;
  }

  .page-with-filters & {
    order: inherit;
  }
}
.nav-expanded .body-overlay {
  opacity: 0;
  height: 0;
}
