/* Module: S */
.homepage__container {
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 50%, #203b71 50%);
  background: -webkit-linear-gradient(left, #ffffff 50%, #203b71 50%);
  background: linear-gradient(to right, #ffffff 50%, #203b71 50%);

  > .page__wrapper {
    display: flex;
  }
}

.homepage__column {
  flex-basis: 50%;
  padding-top: pxToRem(50);
  padding-bottom: pxToRem(60);

  &.easy-access {
    padding-right: pxToRem(41);
    padding-left: 0;
  }

  &.social-feed {
    padding-left: pxToRem(41);
    padding-right: 0;
  }

  &.easy-access,
  &.social-feed {
    margin-left: 0;
    margin-right: 0;
  }
}

.page-heading__heading {
  font-size: pxToRem(30);
}

.page-heading__container {
  .page-heading__heading {
    font-size: pxToRem(30);
  }
  &--contains-search {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    .search__form.inside {
      .search__input {
        padding: pxToRem(22) pxToRem(30) pxToRem(20) pxToRem(20);
      }
    }
  }
}

.inside-page__list {
  margin-left: -1rem;
  margin-right: -1rem;
}

.inside-page__item {
  flex-basis: 100%;
  padding: 0 pxToRem(16);

  &:after {
    left: pxToRem(16);
    right: pxToRem(16);
  }
}

.pagination {
  justify-content: center;
}

.pagination__list {
  width: auto;
}

.pagination__link {
  padding-left: 1rem;
  padding-right: 1rem;

  &.pagination__link--previous,
  &.pagination__link--next {
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
  }
}

.page-controls__title {
  display: block;
}
.page-controls__link {
  &.js-back-to-top {
    display: block;
  }
}

.search-info {
  @include sq-flex-box();
  justify-content: space-between;
  align-items: flex-end;

  div {
    margin-bottom: 0;
  }

  &__pagination {
    flex-basis: 100%;
  }

  &__sortby {
    text-align: right;
    min-width: pxToRem(200);
  }
}

.tag {
  &__list {
    margin-bottom: pxToRem(40) !important;
  }
}

.inpage-utilities {
  max-width: 300px;
}

.page__sidebar {
  .inpage-utilities {
    max-width: inherit;
  }
}

blockquote {
  font-size: 17px;
}

//2 column layout for inside page nav
.detailed-guide__list,
.inside-nav__wrapper {
  &.left2right {
    @include sq-flex-box();
    flex-wrap: wrap;
    li {
      width: 50%;
      padding-right: pxToRem(20);
    }
  }
}
