/* Module: Accordion */
.accordion__target {
    display: none;
    border: pxToRem(1) solid #dadada;
    border-top: 0;

    &.uber-accordion__target-active {
        display: block;
    }
}

.accordion__item {
    h2 {
        margin-top: 0;
        margin-bottom: 0;
    }

    + .accordion__item {
        margin-top: pxToRem(20);
    }
}

.accordion__link {
    position: relative;
    display: block;
    padding: pxToRem(25);
    line-height: 1.25;
    font-size: 1rem;
    color: #000;
    text-decoration: none;
    background-color: #efefef;
    border: pxToRem(1) solid transparent;
    border-bottom: 0;

    &:after {
        position: absolute;
        right: pxToRem(25);
        top: 50%;
        margin-top: pxToRem(-7);
        content: '';
        display: inline-block;
        height: pxToRem(10);
        width: pxToRem(10);
        border-right: pxToRem(2) solid #000;
        border-bottom: pxToRem(2) solid #000;
        transform: rotate(45deg);
    }
    
    &.uber-accordion__button-active {
        background-color: #fff;
        border: pxToRem(1) solid #dadada;
        border-bottom: 0;
        
        &:after {
            transform: rotate(-135deg);
            margin-top: pxToRem(-2);
        }
    }
}

.accordion__target {
    padding: 0 pxToRem(25) pxToRem(25) pxToRem(25);
}

.tabs:not(.social-tabs) {
    border: pxToRem(1) solid #dfdfdf;
}

.tabs__tab-list {
    display: flex;
    flex-wrap: nowrap;
    @extend %list-reset;

    .social-tabs & {
        padding-bottom: 1rem;
        border-bottom: pxToRem(1) solid #3d5d87;
    }
}

.tabs__item {
    display: block;
    flex-grow: 1;
    flex-basis: 0;
    border-right: pxToRem(1) solid #dfdfdf;

    &:last-child {
        border-right: 0;
    }

    .social-tabs & {
        flex-basis: auto;
        flex-grow: 0;
        border-right: 0;
        margin-right: 1rem;
    }
}

.tabs__link {
    position: relative;
    display: block;
    font-size: 1rem;
    line-height: 1.25;
    text-decoration: none;
    font-weight: bold;
    color: #000;
    background-color: #fff;
    padding: pxToRem(22);

    &:hover,
    &:focus {
        background-color: darken(#fff, 2%);
    }

    &.uber-accordion__button-active {
        color: #fff;
        background-color: #333;
    }

    .social-tabs & {
        background-color: transparent;
        padding: 0;

        &:hover,
        &:focus {
            background-color: transparent;
        }

        &.uber-accordion__button-active {
            &:before,
            &:after {
                content: '';
                position: absolute;
                border-style: solid;
                left: 50%;
                bottom: pxToRem(-17);
                width: 0;
                height: 0;
            }

            &:after {
                margin-left: pxToRem(-6);
                border-width: 0 6px 6px 6px;
                border-color: transparent transparent #203b71 transparent;
            }

            &:before {
                margin-left: pxToRem(-7);
                border-width: 0 7px 8px 7px;
                border-color: transparent transparent #3d5d87 transparent;
            }
        }
    }
}

.tabs__tab-content {
    border-top: pxToRem(1) solid #dfdfdf;

    .social-tabs & {
        border-top: 0;
    }
}

.tabs__target {
    color: #262626;
    background-color: #fff;
    line-height: 1.86;
    padding: pxToRem(27) pxToRem(24);

    .social-tabs & {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    > h2, > h3, > h4 {
        &:first-child {
            margin-top: 0;
        }
    }
}

.js-enabled .tabs__target {
    display: none;
    &.uber-accordion__target-active {
        display: block;
    }
}
