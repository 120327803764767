/* Module: Slick */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:after,
  &:before {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }
  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.carousel {
  display: none;

  &.slick-initialized {
    display: block;
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: pxToRem(-6);
  display: inline-block;
  padding: 0;
  width: pxToRem(16);
  height: pxToRem(13);
  background-image: url({{file_dest}}/icon-arrow-right--orange.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;

  &.slick-prev {
    transform: rotate(180deg);
    left: 2rem;
  }

  &.slick-next {
    right: 2rem;
  }
  &.slick-next,
  &.slick-prev {
    display: none !important;

    @media #{$mq-medium} {
      display: inline-block !important;
    }
  }
}

.slick-dots {
  @extend %list-reset;
  display: flex;

  > li {
    margin-right: pxToRem(10);

    &:last-child {
      margin-right: 0;
    }

    button {
      display: block;
      background-color: #eee;
      border: pxToRem(1) solid #000;
      height: pxToRem(10);
      width: pxToRem(10);
      padding: 0;
      border-radius: 50%;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    &.slick-active {
      button {
        background-color: #eb5830;
        border-color: #eb5830;
      }
    }
  }
}

.carousel-controls {
  display: flex;
  justify-content: center;
  position: relative;
  padding: pxToRem(22);
  background-color: #eee;

  @media #{$mq-medium} {
    padding: pxToRem(32);
  }
}
