/* Module: S */
.nav-footer {
  @include sq-flex-box();
  flex-basis: 100%;
  .nav__list {
    display: block;
    width: 26.8%;
    margin-right: 20px;
    &-2col {
      -webkit-columns: 2; /* Chrome, Safari, Opera */
      -moz-columns: 2; /* Firefox */
      columns: 2;
    }

    &:first-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &.inline {
      @include sq-flex-box();
      width: 100%;
    }
  }

  .nav__item {
    display: block;
    .nav__link {
      padding-bottom: 0;
    }
  }
}
