/* Module: S */
.homepage__column {
  padding-top: pxToRem(42);
  padding-bottom: pxToRem(42);

  &.easy-access {
    background-color: #fff;
  }

  &.social-feed {
    background-color: #203b71;
  }

  &.easy-access,
  &.social-feed {
    margin-left: pxToRem(-20);
    margin-right: pxToRem(-20);
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
  }
}

.breadcrumb {
  margin-bottom: pxToRem(6);
}

.breadcrumb__list {
  @extend %list-reset;
  display: flex;
  flex-wrap: wrap;
  .active {
    color: #505050;
    font-size: 0.875rem;
  }
}

.breadcrumb__item {
  &:after {
    content: "»";
    color: #505050;
    display: inline-block;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
}

.breadcrumb__link {
  display: inline-block;
  color: #505050;
  font-size: 0.875rem;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.pagination {
  display: flex;
  margin-top: 2rem;
}

.pagination__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @extend %list-reset;
}

.pagination__link {
  display: block;
  color: #000;
  font-size: 0.875rem;
  font-weight: normal;
  text-decoration: none;
  user-select: none;
  padding: pxToRem(8) pxToRem(12);

  &--active {
    font-weight: bold;
  }

  &--first {
    color: $tomato-dark;
  }

  &:focus,
  &:hover {
    background-color: #efefef;
  }

  &.pagination__link--next,
  &.pagination__link--previous {
    background-image: url({{file_dest}}/icon-arrow-right--orange.svg);
    background-repeat: no-repeat;
    width: pxToRem(16);
    height: pxToRem(35);
    padding-left: pxToRem(16);
    padding-right: pxToRem(16);
  }

  &.pagination__link--next {
    background-position: center top pxToRem(12);
  }

  &.pagination__link--previous {
    transform: rotate(180deg);
    background-position: center top pxToRem(11);
  }
}

.page-heading__heading {
  margin-top: 0;
  margin-bottom: 0;
}

.page-heading__sub-info {
  color: #262626;
  font-size: 0.875rem;
  margin-top: 0.75rem;
}

.page-heading__date {
  color: $off-black;
}

.page-heading__container {
  .page-heading__heading {
    font-size: pxToRem(24);
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: pxToRem(15);
    margin-bottom: 34px !important;
  }

  &--contains-date {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: pxToRem(10);

    .page-heading__heading {
      border-bottom: none;
      padding-bottom: pxToRem(5);
    }
  }

  &--contains-search {
    border-bottom: none;
    padding: pxToRem(20) 0;

    .page-heading__heading {
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: pxToRem(30);
    }

    .search__form.inside {
      margin-top: 0;
      padding-top: 0;

      .search__input {
        border: 1px solid #333333;
        padding: pxToRem(15) pxToRem(20) pxToRem(12);
        color: #000;

        &::-webkit-input-placeholder {
          color: #000;
        }

        &::-moz-placeholder {
          color: #000;
        }

        &:-ms-input-placeholder {
          color: #000;
        }

        &:-moz-placeholder {
          color: #000;
        }
      }
    }
  }
}

.inside-page__list {
  @extend %list-reset;
  display: flex;
  flex-wrap: wrap;

  &-heading {
    font-size: pxToRem(22);
    margin-bottom: 0;
    margin-top: pxToRem(40);
  }
}

.inside-page__item {
  flex-basis: 100%;
  border-bottom: pxToRem(1) solid transparent;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid $tomato-dark;
  }
}

.inside-page__link {
  position: relative;
  display: block;
  color: #262626;
  text-decoration: none;
  padding: pxToRem(27) pxToRem(24) pxToRem(22) 0;
  font-size: 0.875rem;

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &:after {
    position: relative !important;
  }

  &-icon:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: pxToRem(-6.5);
    display: block;
    height: pxToRem(13);
    width: 1rem;
    background-image: url("{{file_dest}}/icon-arrow-right--orange.svg");
    background-repeat: no-repeat;
  }
}

.page-controls__container {
  border-top: pxToRem(1) solid #d8d8d8;
  font-size: 0.875rem;
  margin-top: 2rem;
  padding-top: 1rem;
}

.page-controls__list {
  @extend %list-reset;
  display: flex;
  justify-content: space-between;
}

.page-controls__item {
  max-width: pxToRem(250);

  &:nth-child(2) {
    min-width: pxToRem(120);
  }

  &:last-child {
    text-align: right;
  }
}

.page-controls__link {
  color: #000;
  display: block;
  text-decoration: none;

  &.link-previous {
    margin-left: pxToRem(20);
  }

  &.link-next {
    margin-right: pxToRem(20);
  }
  &.js-back-to-top {
    display: none;
    padding: 0 1rem;
    text-align: center;
  }

  &:focus,
  &:hover {
    .page-controls__indicator {
      text-decoration: underline;
    }
  }
}

.page-controls__title {
  display: none;
}

%shared-link-styles {
  content: "";
  position: absolute;
  background-image: url("{{file_dest}}/icon-arrow-right--orange.svg");
  background-repeat: no-repeat;
  display: inline-block;
  width: pxToRem(16);
  height: pxToRem(13);
}

.page-controls__indicator {
  display: block;
  position: relative;
  margin-bottom: 0.25rem;

  .link-previous & {
    &:before {
      left: pxToRem(-23);
      @extend %shared-link-styles;
      top: pxToRem(2);
      transform: rotate(180deg);
      margin-top: pxToRem(1);
    }
  }

  .link-next & {
    &:after {
      right: pxToRem(-23);
      @extend %shared-link-styles;
      margin-top: pxToRem(2);
      top: pxToRem(2);
    }
  }
}

.accordion {
  &__controls {
    text-align: right;
  }

  &__expand-btn {
    color: $tomato-dark;
    background: transparent;
    border: none;
    padding: 0;
    margin-bottom: pxToRem(20);
    font-size: 0.875rem;
    margin-left: auto;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__link {
    &:hover {
      text-decoration: underline;
    }
  }
}

.btn {
  @include sq-transition(0.3s all);
  display: inline-block;
  background: #323232;
  border: none;
  padding: pxToRem(15) pxToRem(14);
  color: #fff;
  font-size: 0.875rem;
  font-weight: normal;

  &:hover {
    @include sq-transition(0.3s all);
    text-decoration: underline;
    //background-color: #1e1e1e;
  }

  &--orange {
    background: $tomato-dark;

    &:hover {
      @include sq-transition(0.3s all);
      //background-color: #b04426;
    }
  }

  &--light {
    background: #efefef;
    color: #000;
  }
}

.search-info {
  font-size: 0.875rem;
  padding-bottom: pxToRem(15);
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: pxToRem(25);

  div {
    margin-bottom: pxToRem(15);
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__sort-select,
  select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    color: $tomato-dark;
    background: none;
    padding-right: pxToRem(15);
    background: grey;
    background: url("../mysource_files/icon-arrow-down.svg") no-repeat;
    background-position: right;

    &::-ms-expand {
      display: none;
    }
  }
}

.tag {
  &__list {
    margin: 0 !important;
    padding: 0 !important;
    line-height: pxToRem(40);
    margin-bottom: pxToRem(20) !important;
  }

  &__item {
    margin: 0 pxToRem(5) 5px 0 !important;
    padding: 0 !important;
    display: inline-block;

    &:before {
      display: none !important;
    }
  }

  &__link {
    @include sq-transition(0.3s all);
    border: none;
    padding: 13px 45px 12px 13px;
    background: #333333;
    color: #fff;
    font-weight: normal;
    line-height: normal;
    text-decoration: none;
    font-size: 0.875rem;

    &:focus,
    &:hover {
      @include sq-transition(0.3s all);
      background: #000;
      color: #fff;
      text-decoration: underline;
    }

    &:after {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      position: absolute;
      background: url("../mysource_files/icon-x-white.svg") no-repeat;
      right: 12px;
      top: 14px;
    }

    &--clear-filters {
      background: #fff;
      color: $tomato-dark;
      padding: pxToRem(13) pxToRem(45) pxToRem(12) pxToRem(0);

      &:focus,
      &:hover {
        background: #fff;
        color: $tomato-dark;
        text-decoration: underline;
      }

      &:after {
        display: none;
      }
    }
  }
}

.listen-tool {
  position: relative;
  z-index: 5;

  &__wrapper {
    padding: pxToRem(5) 0;
  }

  .rsbtn {
    margin: 0;
  }
}

.page-heading__container {
  .listen-tool {
    padding-top: 0;
  }
}

.page__content > .listen-tool {
  padding: pxToRem(10) 0;
}

.detailed-guide {
  &__list {
    max-width: 850px;
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-bottom: pxToRem(28);
    counter-reset: section;

    &.left2right {
      visibility: visible;
    }
  }

  &__item {
    @include sq-flex-box();
    font-size: pxToRem(16);
    padding-left: 0 !important;
    margin-bottom: pxToRem(17) !important;
    margin-top: 0 !important;

    &:before {
      font-weight: normal !important;
      font-size: pxToRem(16) !important;
      position: relative !important;
      margin-right: pxToRem(10);
      width: 15px;
      color: $tomato-dark;
      counter-increment: section;
      content: counters(section, ".") "." !important;
    }

    &.selected {
      font-weight: bold !important;

      &:before {
        font-weight: bold !important;
      }
    }
  }

  &__link {
    color: #000;
    text-decoration: none;

    &:focus,
    &:hover {
      font-weight: bold;
    }
  }
}

.page__content {
  .inside-nav {
    &__container {
      background-color: $concrete-grey;
      max-width: 850px;
      counter-reset: section;

      .inside-nav__wrapper {
        //visibility: hidden;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          padding-left: 0;
          padding-top: pxToRem(3);
          margin: 0 0 pxToRem(12);
          @include sq-flex-box();

          &:before {
            margin-right: pxToRem(10);
            width: 15px;
            counter-increment: section;
            content: counters(section, ".") ". ";
            color: $tomato-dark;
            font-weight: bold;
          }

          &:last-of-type {
            //margin-bottom: pxToRem(23);
          }
        }

        a {
          color: $base-font-colour;
          text-decoration: none;
          width: 100%;
          &:hover,
          &:focus {
            //color: $tomato-dark;
            text-decoration: underline;
          }
        }

        .current a {
          font-weight: bold;
        }

        &.left2right {
          visibility: visible;
        }
      }
      /*
      * Uber accordion style overrides
      */
      .accordion__link {
        display: block;
        font-size: pxToRem(14);
        font-weight: bold;
        margin-bottom: pxToRem(42);
        margin-top: pxToRem(10);
        padding: pxToRem(23) pxToRem(19) pxToRem(22);

        &:after {
          border-right-color: $tomato-dark;
          border-bottom-color: $tomato-dark;
          height: pxToRem(9);
          right: pxToRem(18);
          top: 51%;
          width: pxToRem(9);
        }

        &.uber-accordion__button {
          &-active {
            background-color: $concrete-grey;
            margin-bottom: 0;

            & + .accordion__target {
              margin-bottom: pxToRem(42);
              padding: 0 pxToRem(21);
            }
          }
        }
      }
    }
  }
}

.accordion__target > * {
  margin-top: 0;
}

.accordion__target table {
  table-layout: fixed;
  margin-bottom: pxToRem(20);
}

.tabs__link {
  height: 100%;
  &:hover {
    text-decoration: underline;
  }
}

.table {
  &__container {
    overflow: auto;
  }
}

.carousel__caption {
  padding: pxToRem(70) pxToRem(25) pxToRem(16) pxToRem(25);
  position: absolute;
  bottom: 0;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
  color: white;
  width: 100%;
  font-weight: 100;
}
.slick-slide {
  position: relative;
}

.carousel-controls {
  .slick-dots > li.slick-active button {
    background-color: $tomato-dark;
    border-color: $tomato-dark;
  }
}

.footer {
  .external-link:after {
    top: pxToRem(5);
  }
}

//ticket SDNSW-3612
// .external-link {
//   position: relative;
//   &:after {
//     content: "";
//     background: url(../mysource_files/icon-external-link.svg) no-repeat;
//     display: inline-block;
//     height: pxToRem(12);
//     width: pxToRem(12);
//     margin-left: pxToRem(5);
//     top: pxToRem(1);
//   }
//   &.btn {
//     &:after {
//       background: url(../mysource_files/icon-external-link-white.svg) no-repeat;
//     }
//   }
// }

.o-long-doc {
  .page__content {
    order: 1;
  }

  .page__sidebar {
    margin-top: pxToRem(10);
    margin-bottom: pxToRem(20);

    .inpage-utilities {
      .component__list {
        margin-bottom: pxToRem(20);
      }
    }
  }

  #toc-main-heading {
    padding-left: 3.875rem;
    top: pxToRem(25);
  }
  #toc-list {
    overflow-y: scroll;
    top: pxToRem(50);
    width: 100%;
    margin-left: pxToRem(-40);
    list-style: none;
    .toc-list {
      list-style: none;
      padding-left: pxToRem(20);
    }
    a {
      color: #000;
      text-decoration: none;
    }
  }
  .current {
    font-weight: bold;
    text-decoration: underline;
    color: $tomato-dark;
  }
  #btn-show-hde-toc {
    cursor: pointer;
    display: none;
  }
  #btn-show-hde-toc img {
    width: 20px;
    height: 20px;
    position: fixed;
    top: calc(47% + 70px);
  }
}

.inpage-utilities {
  .share__options {
    .share__option {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      &:before,
      &:after {
        display: none !important;
      }
    }
  }
}

.skip-links {
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 20px;
  background: white;
}

// Notifications

.notification {
  font-size: pxToRem(16);
  line-height: 1.56;
  color: #181818;
  clear: both;
  overflow: auto;
  padding-left: pxToRem(65);

  ol li {
    padding-left: 2rem !important;
  }

  & svg + h1,
  & svg + h2,
  & svg + h3,
  & svg + h4,
  & svg + h5,
  & svg + ul,
  & svg + p {
    margin-top: 0;
  }
}

.notification .svg-icon {
  float: left;
  display: block;
  width: pxToRem(50);
  height: pxToRem(50);
  border-bottom: pxToRem(2) solid #1b1b1b;
  padding-left: pxToRem(11);
  padding-right: pxToRem(11);
  margin-right: 0;
  margin-left: pxToRem(-65);
  fill: #000;
}

.notification-error .svg-icon {
  fill: #fff;
  background-color: #d52b1e;
}

.notification-warning .svg-icon {
  background-color: #ffdf00;
}

.notification-ok .svg-icon {
  background-color: #34b233;
}

.back-to-top {
  display: none;
  height: 60px;
  width: 100%;
  position: static;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 20px 0 0;
  &.fixed {
    position: fixed;
  }
}

.back-to-top__anchor {
  width: 100%;
  height: 40px;
  padding: 12px 0 0;
  background-color: $button-bg;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-size: 0.9em;
  min-width: 125px;
  text-align: center;
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: white;
  }
}

blockquote {
  background: #f9f9f9;
  border-left: 0.625rem solid #ccc;
  padding: 20px 20px;
  margin: 30px 0;
  font-size: 15px;
  p {
    display: inline;
  }
}



// rnswdi Design Improvements

// IN PAGE ALERT
.in-page-alerts__wrapper{
  background-color: #f2f2f2;
  border-left: 5px #203B70 solid;
  height: auto;
  padding: 30px 10px 24px 10px;
  text-align: start;
  margin-bottom: 34px;
  letter-spacing: -0.35px;
  display: flex;
  align-items: center;
  
  @media screen and (min-width: $desktop) {
    // height: 211px;
    padding: 28px 32px 22px 27px !important;
    // margin-top: 34px;
    
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    // height: 211px;
    padding: 19px 30px 19px 25px;
    // margin-top: 34px;
  }
}

.in-page-alerts__wrapper.notification--info{
  background-color: #EBEEF5;
  border-left: 5px #224893 solid;
}

.in-page-alerts__wrapper.notification--error{
  background-color: #F8E8EB;
  border-left: 5px #B4052C solid;
}

.in-page-alerts__wrapper.notification--hint{
  background-color: #FCEFE6;
  border-left: 5px #E37932 solid;
}

.in-page-alerts__wrapper.notification--tick{
  background-color: #E6F7E7;
  border-left: 5px #00A400 solid;
}

.in-page-alerts__container{
  display: flex;
}

.in-page-alerts__icon-container{

  margin-right: 17px;
  margin-left: 8px;
  min-width: 20px;
  height: 20px;

  @media screen and (min-width: $desktop) {
    margin-right: 20px !important;
  }
  

  @media screen and (min-width: 900px) {
    margin-right: 10px;
    min-width: 24px;
    height: 24px;
    margin-left: 0;
  }
  
}

.in-page-alerts__icon{
  background-color: inherit !important;
  border-radius: 50%;
  width: 100%; 
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .in-page-alerts__wrapper.alert--blue .in-page-alerts__icon{
//   background-color: #224893;
// }

// .in-page-alerts__wrapper.alert--red .in-page-alerts__icon{
//   background-color: #B4052C;
// }

// .in-page-alerts__wrapper.alert--orange .in-page-alerts__icon{
//   background-color: #E37932;
// }

// .in-page-alerts__wrapper.alert--green .in-page-alerts__icon{
//   background-color: #00A400;
// }

.in-page-alerts__title{
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.35px;
  margin: -5px 0 0 0;
  width: 100%;
  margin-bottom: 9px;

  @media screen and (min-width: $desktop) {
    margin-bottom: 4px;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    margin-bottom: 9px;
  }  
} 

.in-page-alerts__description{
  display: inline-block;
  margin: 0 !important;
  // margin-top: 10px;
  line-height: 1.8;

  @media screen and (min-width: $desktop) {
    // margin-top: 2px;
    line-height: 1.5;
  }

  p{
    margin: 0;
  }
}

// ON THIS PAGE
.on-this-page__wrapper{
  background-color: white;
  // height: 167px;
  // padding: 9px 10px;
  padding: 0 15px;
  text-align: start;
  margin-bottom: 34px;
  letter-spacing: -0.35px;
  border-left: 5px #203B70 solid;
  


  @media screen and (min-width: $desktop) {
    // height: 211px;
    // padding: 22px 32px 22px 27px;
    padding: 0 32px 0 27px;
    // margin-top: 34px;
    
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    // height: 211px;
    // padding: 19px 30px 19px 25px;
    padding: 0px 30px 0px 25px;
    // margin-top: 34px;
  }
}

.on-this-page__heading{
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.35px;
  margin: 0;
  margin-top: 14px;
  margin-bottom: 2px;

  @media screen and (min-width: $desktop) {
    font-size: 20px;
    margin-bottom: 8px;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 20px;
    margin-bottom: 8px;
  }

  
}

.on-this-page__nav{
  display: flex;
  flex-wrap: wrap;
  line-height: 35px;

  @media screen and (min-width: $desktop) {
    line-height: 33px;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    line-height: 33px;
  }
  
}

.on-this-page__links{
  width: 100%;
  color: #203B70 !important;
  letter-spacing: -0.25px;
  font-weight: 600;
  // padding: 1px 0;

  @media screen and (min-width: $desktop) {
    font-size: 14px;
    letter-spacing: -0.35px;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 14px;
    letter-spacing: -0.35px;
  }
  
}

//INFORMATION TIP BOX
.information-tip__wrapper{
  background-color: #f2f2f2;
  padding: 18px 15px;
  // padding: 0 15px;
  text-align: start;
  margin-bottom: 34px;
  border-left: 5px #203B70 solid;
  


  @media screen and (min-width: $desktop) {
    // height: 211px;
    padding: 26px 32px 22px 16px;
    // padding: 0 32px 0 27px;
    margin-bottom: 34px;
    
  }

  // @media (min-width: 900px) and (max-width: 1200px) {
  //   // height: 211px;
  //   padding: 19px 30px 19px 25px;
  //   margin-top: 34px;
  // }
}

.information-tip__heading{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.35px;
  margin: 0;
  margin-bottom: 2px;

  @media screen and (min-width: $desktop) {
    font-size: 19px;
    letter-spacing: -0.6px;
    margin-bottom: 8px;
  }

  // @media (min-width: 900px) and (max-width: 1200px) {
  //   font-size: 20px;
  //   margin-bottom: 8px;
  // }

  
}

.information-tip__nav{
  display: flex;
  flex-wrap: wrap;
  line-height: 35px;

  @media screen and (min-width: $desktop) {
    line-height: 33px;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    line-height: 33px;
  }
  
}

.information-tip__text{
  margin-bottom: 5px;
  letter-spacing: 1;
}

.information-tip__links{
  width: 100%;
  color: #203B70 !important;
  letter-spacing: -0.25px;
  font-weight: 600;
  // padding: 1px 0;

  @media screen and (min-width: $desktop) {
    font-size: 14px;
    letter-spacing: 0.2px;
    margin-top: 12px;
  }

  // @media (min-width: 900px) and (max-width: 1200px) {
  //   font-size: 14px;
  //   letter-spacing: -0.35px;
  // }
  
}

#rslightbox_overlay{
  z-index: 9999999;
}

#rslightbox_contentcontainer{
  z-index: 9999999;
}


// SUPPORT-439358: Accordion and Tabs same Heading Styles

.accordion__headings{
  font-size: 1.375rem;
  line-height: 1.28;
  font-weight: 700;
}

.tab__headings-mobile{
  font-size: 1.375rem;
  line-height: 1.28;
  font-weight: 700;
}

.icon-panels__heading{
  display: inline-block;
  font-size: 1.125rem;
  line-height: 1.28;
  font-weight: 700;
  margin-bottom: 7px;
}