// Base font
$base-font-scale: 1; // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #222;

$base-page-width: 80rem;

$base-page-width: 80rem;

// Font Family
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
$montserrat: "Montserrat", Arial, Helvetica, sans-serif;


// Default Breakpoints
// $tablet: 768px;
// $desktop: 1200px;

$tablet: 37.5rem;
$desktop: 60rem;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

// theme colours
$theme-black:     rgba(0, 0, 0, 0.9);
$theme-navy:      rgba(0, 40, 58, 0.9);
$theme-plum:      rgba(74, 0, 41, 0.9);
$theme-purple:    rgba(53, 41, 65, 0.9);
$theme-green:     rgba(14, 46, 18, 0.9);

// Other colours
$black:           #000000;

$dusty-grey:      #dfdfdf;
$concrete-grey:   #efefef;
$dolphin-grey:    #eeeeee;
$off-grey:        #f2f2f2;
$pinkish-grey:    #cacaca;
$dark-grey:       #3c3533;
$off-black:       #262626;
$hover-red:       #a63b1e;
$button-bg:         #333;

$tomato:            #eb5830;
$tomato-light:      #FF7049;
$tomato-dark:       #CB4C29;

// Breakpoints
$mq-medium:   'screen and (min-width: 60em)';       // 960px
$mq-1100:     'screen and (min-width: 68.750em)';   // 1100px
$mq-1260:     'screen and (min-width: 78.750em)';   // 1260px
