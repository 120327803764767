/* Module: S */
.video-list {
    width: 100%;
    margin: 0 0 pxToRem(25) 0;
    
    &__item {
        width: 100%;
        height: 220px;
        margin: 0 0 pxToRem(20) 0;

        &:last-child {
            margin: 0;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}