/* Module: S */
.related-links__container {
  padding-top: pxToRem(45);
  padding-bottom: pxToRem(45);
  margin-top: pxToRem(90);
  margin-bottom: pxToRem(-90);
}

.related-links__list {
  @include sq-flex-box();
  margin-left: pxToRem(-18);
  margin-right: pxToRem(-18);
}

.related-links__item {
  margin-bottom: 0;
  padding-left: pxToRem(18);
  padding-right: pxToRem(18);
  flex-grow: 1;
  flex-basis: 0;
}
