/* Module: S */
.sq-form {
  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
    color: #d0021b;
  }

  label {
    font-size: 0.875rem;
    font-weight: 500;
  }

  .sq-form-required-field-note {
    color: #d0021b;
  }

  .sq-form-section-title {
    font-size: pxToRem(22);
    margin: 0;
    border: 0;
    padding-top: 0;
  }

  .sq-form-question.sq-form-question-text {
    font-size: 0.875rem;
    margin-top: pxToRem(10);
    margin-bottom: 0;
  }

  input[type="text"].sq-form-field,
  input[type="file"].sq-form-field,
  input[type="email"].sq-form-field,
  input[type="number"].sq-form-field,
  input[type="password"].sq-form-field,
  textarea.sq-form-field {
    width: 100%;
    max-width: pxToRem(305);
    padding: pxToRem(8);
    font-size: 0.875rem;
  }

  input[type="button"],
  input[type="submit"] {
    @include sq-transition(0.3s all);
    background: #323232;
    border: none;
    padding: pxToRem(15) pxToRem(20);
    color: #fff;
    font-size: 0.875rem;
    margin-right: pxToRem(15);
    font-weight: normal;
    &:hover {
      @include sq-transition(0.3s all);
      //background-color: #1e1e1e;
      text-decoration: underline;
    }
  }
  select.sq-form-field {
    border-radius: 0;
    max-width: pxToRem(305);
    padding: pxToRem(8);
    font-size: 0.875rem;
    width: 100%;
  }

  input[type="radio"].sq-form-field,
  input[type="checkbox"].sq-form-field {
    width: 15px;
    height: 15px;
    margin-left: 0;
  }

  .form__radio {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;

    .sq-form-field {
      margin-right: pxToRem(10);
    }

    .sq-form-question.sq-form-question-text {
      margin-left: pxToRem(27);
    }
  }

  .sq-form-question {
    padding: 0;
    margin-top: pxToRem(15);
  }

  .sq-form-question-note {
    font-style: normal;
  }

  .sq-form-question-error {
    padding: pxToRem(16);
  }

  .sq-form-error {
    font-weight: normal;
    font-size: 0.875rem;
  }

  .sq-form-question-answer {
    ul {
      padding-left: 0 !important;
      li {
        padding-left: 0 !important;
        &:before {
          display: none !important;
        }
      }
    }
  }

  .sq-form-question-datetime {
    .sq-form-question-answer {
      @include sq-flex-box();
      flex-wrap: wrap;
      input[type="text"].sq-form-field {
        width: auto;
      }
    }
  }
}
