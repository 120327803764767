/* Module: S */
.component {
  + .component {
    margin-top: pxToRem(15);
  }
}

.component__heading {
  color: #fff;
  font-size: pxToRem(22);
  line-height: 1.45;
  background-color: #333;
  margin-top: 0;
  margin-bottom: 0;
  padding: pxToRem(20) pxToRem(25);

  .component-as-accordion & {
    padding: 0;
  }
}

.component__heading--toggler {
  position: relative;
  background-color: transparent;
  color: inherit;
  font-size: pxToRem(16);
  font-weight: 500;
  text-align: inherit;
  width: 100%;
  border: 0;
  background-color: transparent;
  padding: pxToRem(20);

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: pxToRem(25);
    margin-top: pxToRem(-7);
    display: block;
    height: pxToRem(10);
    width: pxToRem(10);
    border-right: pxToRem(3) solid $tomato-light;
    border-bottom: pxToRem(3) solid $tomato-light;
    transform: rotate(45deg);

    .active & {
      margin-top: pxToRem(-2);
      transform: rotate(-135deg);
    }
  }
}

.component__list {
  @extend %list-reset;
  background-color: #efefef;
  color: $off-black;
  padding: pxToRem(20) pxToRem(25);

  .component-as-accordion & {
    display: none;
  }

  .cloned-aside .component-as-accordion.active & {
    margin-bottom: pxToRem(40);
  }

  .active & {
    display: block;
  }

  &.invert-colors {
    background-color: #333;
  }

  .datepicker {
    font-size: pxToRem(16);
    padding: pxToRem(8) pxToRem(14);
    width: 100%;
    border: none;
    cursor: pointer;

    background-image: url("../mysource_files/icon-calendar.svg");
    background-repeat: no-repeat;
    background-position: center right;

    &--to {
      margin-top: pxToRem(15);
    }

    &--submit {
      margin-top: pxToRem(15);
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #000;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #000;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #000;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #000;
    }
  }

  &--checkbox-table table {
    tbody {
      border: none;
    }
    tr {
      border: none !important;

      &:first-child td {
        padding-top: 0 !important;
      }

      &:last-child td {
        border-bottom: none;
        padding-bottom: 0 !important;
      }
    }
    td {
      @include sq-flex-box();
      border-bottom: 0.0625rem solid #d8d8d8;
      border-left: none !important;
      border-right: none !important;
      position: relative;
      padding: pxToRem(10) 0 !important;
      label {
        font-size: pxToRem(16);
        font-weight: normal;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      input {
        display: none;
        &:checked + label {
          font-weight: bold;
        }
      }
    }
  }
}

.component__item {
  border-bottom: pxToRem(1) solid #d8d8d8;
  padding-bottom: pxToRem(10);
  margin-bottom: pxToRem(12);
  position: relative;
  .invert-colors & {
    border-bottom-color: #4a4a4a;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }

  &.selected {
    h3 {
      font-weight: bold;
    }
  }

  input[type="checkbox"]:checked + label {
    font-weight: bold;
  }

  .component__link--share {
    background: transparent;
    border: none;
    padding: 0;
  }

  .share__options {
    &--active {
      display: block;
      z-index: 10;
    }
    display: none;
    background: white;
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
    width: pxToRem(305);
    left: pxToRem(-25);
    top: pxToRem(33);
    position: absolute;
    z-index: 2;

    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.42);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.42);

    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: pxToRem(10) solid transparent;
      border-right: pxToRem(10) solid transparent;
      border-bottom: pxToRem(10) solid white;
      top: pxToRem(-10);
      left: pxToRem(25);
    }

    li {
      list-style: none !important;
      padding: 0 1.125rem 0 1.25rem;
      &:first-child {
        a {
          border-top: none;
        }
      }
      a {
        font-size: pxToRem(16);
        font-weight: bold;
        text-decoration: none;
        border-top: 0.0625rem solid rgba(199, 199, 199, 0.4);
        color: #000;
        display: block;
        line-height: 1.25;
        padding: 1.375rem 0 1.4375rem 2.5rem;
        position: relative;

        .share__option-svg {
          left: 0;
          position: absolute;
          top: 1.375rem;

          svg {
            width: 17px;
            height: 17px;
            fill: #000;
          }
        }
        &:hover,
        &:focus {
          //color: $tomato-dark;
          text-decoration: underline;
          .share__option-svg {
            svg {
              //fill: #fa612a;
            }
          }
        }
      }
    }
  }
}

.component__description,
.component__title {
  margin-top: 0;
  margin-bottom: 0;
}

.component__title {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: pxToRem(19);

  .filters & {
    font-size: pxToRem(16);
    font-weight: normal;
  }

  &:after {
    content: "";
    margin-left: pxToRem(8);
    background-image: url("{{file_dest}}/icon-arrow-right--orange.svg");
    background-repeat: no-repeat;
    width: pxToRem(16);
    height: pxToRem(13);
    display: inline-block;
    vertical-align: middle;
  }

  .selected & {
    &:after {
      background-image: url("{{file_dest}}/icon-arrow-right--orange.svg");
    }
  }
}

.component__description {
  color: $off-black;
  margin-top: 0.5rem;
}

.component__link {
  color: $off-black;
  font-size: 0.875rem;
  text-decoration: none;

  &:focus,
  &:hover {
    .component__title {
      text-decoration: underline;
    }
  }

  .invert-colors & {
    color: #fff;
  }
}

.component__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: pxToRem(8);
  margin-top: pxToRem(-4);
  min-width: 1.2rem;

  &.icon-share-page {
    width: 1rem;
    height: pxToRem(17);
  }

  &.icon-download {
    width: pxToRem(17);
    height: pxToRem(21);
  }

  + .component__link {
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

.component.filters {
  .component__title:after {
    display: none;
  }
}

/*
.component--toc {
  .component__heading {
    background: #efefef;
    color: #000;
  }

  .cloned-aside & {
    margin-bottom: pxToRem(40);

    &.active {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none !important;
    padding: 0 !important;
    margin: 0 !important;

    li {
      margin-bottom: pxToRem(10) !important;
      margin-top: 0 !important;
      padding: 0 !important;

      &::before {
        display: none !important;
      }

      ul {
        margin-top: pxToRem(10) !important;
        padding-left: pxToRem(30) !important;
        list-style-position: inside;
        list-style: disc !important;
      }
    }
  }

  a {
    color: #000000;
    text-decoration: none;

    &:hover {
      font-weight: bold;
    }
  }

  .component__list {
    padding-top: 0;
  }
}
*/

.picker {
  width: 255px;
  font-size: 14px;
  .picker__table {
    margin-bottom: 0;
  }

  tr th {
    border: none;
    padding: 10px 0px;
    text-align: center;
  }
  tr td {
    padding: 0;
    &:first-child {
      padding: 0;
      width: auto;
      vertical-align: middle;
      text-align: center;
      font-size: 14px;
      line-height: 1.4;
    }
  }

  .picker__nav--prev:hover,
  .picker__nav--next:hover {
    background: transparent;
    &:before {
      border-right-color: $tomato-dark;
      border-left-color: $tomato-dark;
    }
  }

  .picker__button--today,
  .picker__button--clear,
  .picker__button--close {
    border: none;
    &:hover {
      background: transparent;
      border-bottom: none;
      color: $tomato-dark;
    }
  }

  .picker__weekday {
    color: #fff;
  }

  .picker__date {
    font-size: 14px;
  }
}


//rnswdi Design Improvements
.secondary-nav{
  border: 1px #c4c4c4 solid;
  margin-bottom: 30px;
  letter-spacing: -0.35px;

  .component__list{
    padding-top: 14px;
    padding-left: 44px;
    // background-color: #efefef;
    background-color: white;


    @media screen and (min-width: $desktop) {
      padding-top: 28px;
      padding-left: 35px;
    }
  }

  &__heading{
    color: #203B70;
    font-size: 16px;
    margin: 0;
    line-height: 1.5;
    margin-bottom: 13px;

    @media screen and (min-width: $desktop) {
      font-size: 24px;
      margin: 0;
      line-height: 1.3;
      // margin-bottom: 38px;
      margin-bottom: 27px;
    }
  }


  .component__item{
    border-bottom: 0;
    padding-bottom: 0;
    
    @media screen and (min-width: $desktop) {
      // margin-bottom: 22px;
    }
    
    
  }

  &__parent{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;

    @media screen and (min-width: $desktop) {
      font-weight: 500;
      font-size: 18px;
      margin: 0;
      margin-bottom: 7px;
      padding: 0;
    }
  
    // @media (min-width: 900px) and (max-width: 1200px) {
      
    // }
  }

  &__parent-links{
    color: #333;
    cursor: pointer;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }

  &__parent-links.focus-link{
    font-weight: 600 !important;
    pointer-events: none;
  }

  &__child-menu{
    padding-left: 0;
    list-style-type: none;    
  }

  &__child-menu.focus-nav .secondary-nav__parent{
    font-weight: 600;
  }

  &__child-menu.focus-nav .secondary-nav__child-items.focus-nav{
    
    .secondary-nav__child-links.focus-link{
      text-decoration: underline;
    }

    .secondary-nav__next-child-links.focus-link{
      text-decoration: underline;
    }
    
  }

  &__child-menu.focus-nav::before{
    color: #3a3a3a;
    font-size: 14px;
    border-left: 0;
    background-color: #203B70;
    border-left: 4px #203B70 solid;
    content: '';
    position: absolute;
    top: 7px;
    left: -25px;
    width: 4px;
    height: 95%;

    @media screen and (min-width: $desktop) {
      left: -16px;
      top: 5px;
      height: 95.5%;
    }
    
  }

  &__child-items{

      padding-left: 18px;
      margin: -1px 0;

    @media screen and (min-width: $desktop) {
      margin: 0;
      margin-left: 2px;
      margin-bottom: 12px; 
      line-height: 1.8;
    }
  }
  
  &__child-links{
    // all: unset;
    text-decoration: none;
    color: #203B70;
    padding-bottom: 8px;

    &:hover{
      text-decoration: underline;
    }

    @media screen and (min-width: $desktop) {
      font-size: 16px; 
      color: black;
    }
    
  }

  &__child-links.focus-link{
    // font-weight: 600 !important;
    text-decoration: underline;
    pointer-events: none;

  }

  &__next-child-menu{
    // all: unset;
    list-style-type: none;
    color: #203B70;
    padding-left: 18px;
    
  }

  &__next-child-links{
    // all: unset;
    text-decoration: none;
    color: #203B70;
    

    &:hover{
      text-decoration: underline;
    }


    @media screen and (min-width: $desktop) {
      font-size: 16px; 
      color: black;
    }
    
  }

  &__next-child-links.focus-link{
    // font-weight: 600 !important;
    text-decoration: underline;
    pointer-events: none;
  }

  
}
