/* Module: S */
.quick-links__column {
  flex-basis: calc(50% - 14px);

  &:nth-child(odd) {
    margin-right: 1.75rem;
  }

  &:first-child {
    padding-top: 1.75rem;
  }

  &:nth-child(n + 3) {
    padding-top: 2em;
  }
}
