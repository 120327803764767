/* Module: S */
.homepage-banner__wrapper{
    display: block;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $montserrat;
    background-color: #203B70;
    margin-bottom: 20px;
    

    @media screen and (min-width: $desktop) {
        width: 100%;
        min-height: 283px;
    }

    @media (min-width: 900px) and (max-width: 1200px) {
        width: 100%;
        min-height: 283px;
    }

    .homepage-banner__container{
        display: flex;
        flex-direction: column-reverse;

        @media screen and (min-width: $desktop) {
            display: grid;
            position: relative;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
        }
    }

    .homepage-banner__container.text-only{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        // height: 293px;
        margin: 0 auto;

        @media screen and (min-width: $desktop){
            // height: 293px
        }
    }

    .homepage-banner__container.text-only .homepage-banner__text{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        

    }

    .homepage-banner__container.text-only .homepage-banner__description{
        width: 100%;

    }


    .homepage-banner__text-container{
        display: flex;
        justify-content: center;
        padding: 16px;

        // @media screen and (max-width: 1200px ) {
            
        //     justify-content: flex-end;
        //     margin: auto 4rem;
        // }
        

        @media screen and (min-width: $desktop) {
            
            justify-content: flex-end;
            margin: auto 8rem auto 4rem ;
            padding: 0;
        }

        @media (min-width: 900px) and (max-width: 959px) {
            margin: auto ;
            padding: 0;
        }

    }

    .page__wrapper2 {
      width: 100%;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1.25rem;
    
    } 

    .homepage-banner__text{
        font-weight: 600;
        font-size: 16px;
        line-height: 19.5px;
        color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 10px 1rem;
        letter-spacing: -0.53px;
        margin: 0 auto;
        width: 100%;

        @media screen and (min-width: $desktop) {
            padding: 0;
            max-width: 477px;
            margin: 0;
            font-weight: 700;
            font-size: 26px;
            color: white;
            line-height: 31.69px;
            display: flex;
        }

        @media (min-width: 900px) and (max-width: 959px) {
            padding: 0;
            max-width: 477px;
            margin: 0;
            font-weight: 700;
            font-size: 26px;
            color: white;
            line-height: 31.69px;
            display: flex;
        }
    }

    .homepage-banner__text.text-only{
        max-width: unset !important;
    }

    .homepage-banner__description{
        margin: 0;
        margin-bottom: 18px;

        @media (min-width: 900px ) and (max-width: 959px ) {
            font-size: 18px;
            width: 100%;
            text-align: center;
            margin-top: 18px;
        }

        @media screen and (min-width: $desktop) {
            // margin: 30px 0;
            
            margin-bottom: 30px;
        }
    }

    .homepage-banner__container.text-only .homepage-banner__cta-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .homepage-banner__cta-container{
        width: 100%;
        display: flex;
        justify-content: center;

        @media screen and (min-width: $desktop) {
            
            justify-content: flex-start;
        }

        @media screen and (max-width: 900px) {
            justify-content: center;
        }
    }

    .homepage-banner__cta{
        border: 1px white solid;
        padding: 2px 10px;
        font-weight: 600;
        color: white;
        text-decoration: none;
        font-size: 15px;
        letter-spacing: -0.53px;
        display: none;
        width: 146px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover{
            background-color: white;
            color: #C94D31;
        }

        // @media screen and (min-width: $desktop) {
        //     display: inline-block;
        // }
    }

    .homepage-banner__banner-img{
        overflow: hidden;
        width: 100%;
        height: auto;
    }

    .homepage-banner__img{
        background-image: url("../mysource_files/sydneyharbour.png");
        background-repeat:no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 121px;

        @media screen and (min-width: $desktop){
            // object-fit: cover;
            background-size: cover;
            object-position: center center;
            width: 100%;
            height: 283px;
        }
    }
}


.homepage-banner__wrapper.text-only{
    min-height: unset !important;

    @media screen and (min-width: 960px) {
        padding: 40px 0;
    }
    
}

