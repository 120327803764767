/* Module: S */
.header {
  padding-top: pxToRem(21);
  padding-bottom: pxToRem(14);

  > .page__wrapper {
    flex-direction: row;
  }
}

.logo__container {
  height: pxToRem(71);
}

.logo {
  max-height: pxToRem(71);
  max-width: pxToRem(188);
  height: pxToRem(71);
  width: pxToRem(188);
}

.nav {
  &.nav-header {
    display: flex;
    order: inherit;
    margin-left: auto;
  }

  + .search__form {
    margin-left: 0.5rem;
  }
}

.nav__list {
  display: flex;

  .nav-header & {
    display: flex;
    background-color: transparent;
    margin: 0;
    margin-top: pxToRem(26);
    border-top: 0;

    &.active {
      display: flex;
    }
  }
}

.nav__item {
  margin-left: pxToRem(8);
  margin-right: pxToRem(8);
}

.nav__link {
  font-size: 0.875rem;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  .nav-header & {
    padding: 0;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.header__actions {
  display: none;
}

#google-translate__container {
  padding-top: pxToRem(4);
  margin-left: 1.5rem;
}
