/* Module: S */
.consultations {
  &__container {
    padding: pxToRem(26);
    svg {
      width: pxToRem(40);
      height: pxToRem(40);
      margin-bottom: pxToRem(15);
      fill: $tomato-dark;
    }
  }

  .submissions.accordion {
    margin-top: pxToRem(30);
  }

  .submissions__section {
    margin-bottom: pxToRem(40);
  }

  .submissions__title {
    font-size: pxToRem(18);
    margin-bottom: pxToRem(20);
    margin-top: pxToRem(24);
  }

  .submissions__text,
  &__graph-bottom {
    font-size: 0.875rem;
    > div > div {
      font-weight: bold;
    }
  }

  &__heading {
    font-size: pxToRem(22);
    margin-top: 0;
  }

  &__link {
    color: #262626;
    background: #efefef;
    text-decoration: none;
    display: block;
    margin-bottom: pxToRem(30);

    &:hover, &:focus {
      background-color: $dusty-grey;
    }
  }

  &__title {
    margin-top: 0;
    color: #262626;
    font-size: 0.875rem;
    line-height: pxToRem(22);
  }

  &__content {
    font-size: 0.875rem;
  }

  &__graph-heading {
    display: none;
  }

  &__graph-started {
    margin-bottom: pxToRem(10);
    > div {
      font-weight: bold;
    }
  }

  &__progress-count {
    position: absolute;
    margin: auto auto;
    width: 100%;
    text-align: center;
    margin-top: pxToRem(8);
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
  }

  //view all button size

  &__view-all {
    @include sq-flex-box();
    @include sq-transition(0.3s all);
    align-items: center;
    margin: auto auto;
    background: $tomato-dark;
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;
    padding: pxToRem(12) pxToRem(26);
    max-width: pxToRem(250);
    text-align: center;
    svg {
      width: pxToRem(27);
      height: pxToRem(27);
      margin-right: pxToRem(12);
    }

    &:hover {
      @include sq-transition(0.3s all);
      background: #262626;
    }
  }

  .progressbar__container {
    background-color: #888;
    height: pxToRem(32);
    margin: pxToRem(20) 0;
    position: relative;
    width: 100%;
  }

  .progressbar {
    //font-size: 0;
    height: pxToRem(32);
    position: absolute;
    width: 100%;
  }

  &.consultations-detail {
    .consultations__summary {
      margin-top: pxToRem(30);
    }
    p {
      font-size: 0.875rem;
      line-height: pxToRem(22);
    }

    .consultations__documents-heading {
      font-size: pxToRem(22);
      margin-bottom: pxToRem(26);
    }

    .consultations__documents {
      margin-bottom: pxToRem(40);
      font-size: 0.875rem;
      ul {
        padding-left: 0 !important;
        li {
          padding-left: 0 !important;
          &:before {
            display: none !important;
          }

          a {
            @include sq-flex-box();
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
            &:before {
              content: "";
              display: block;
              width: 42px;
              min-width: 42px;
              height: 30px;
              margin-top: -6px;
            }
          }
          //pdf icon
          a[href$=".pdf"]:before {
            background: url("../mysource_files/icon-file-pdf.svg") no-repeat;
          }
        }
      }
    }

    //accordion style

    .tabs {
      margin-top: pxToRem(30);
      margin-bottom: 40px;
    }

    .tabs,
    .tabs__item,
    .tabs__tab-content {
      border: 0;
    }

    .tabs__item {
      flex-grow: inherit;
      flex-basis: auto;
    }

    .tabs__link {
      font-weight: normal;
      font-size: 0.875rem;
    }

    .tabs__tab-content,
    .tabs__link.uber-accordion__button-active {
      background: #efefef;
      color: #262626;
    }

    .tabs__link.uber-accordion__button-active {
      font-weight: bold;
    }

    .tabs__target {
      padding: pxToRem(27) pxToRem(22);
      font-size: 0.875rem;
      background: transparent;
      ul,
      ol {
        padding-left: 0 !important;
        margin-left: 0 !important;
      }
    }

    .consultations__subheading {
      font-size: pxToRem(22);
    }

    .progressbar__container {
      height: pxToRem(48);
      margin: pxToRem(14) 0;
    }

    .consultations__graph {
      max-width: pxToRem(640);
    }

    .progressbar {
      //font-size: 0;
      height: pxToRem(48);
    }

    .consultations__graph {
      font-size: 0.875rem;
    }

    .consultations__progress-count {
      margin-top: pxToRem(15);
    }

    .consultations__graph-started > div,
    .consultations__graph-bottom > div > div {
      font-weight: normal;
      display: inline;
    }

    .consultations__graph-heading {
      @include sq-flex-box();
      align-items: center;
      display: block;
      font-size: 0.875rem;
      margin-bottom: 10px;
      &:before {
        content: "";
        width: 27px;
        min-width: 20px;
        height: 20px;
        display: inline-block;
        background: url(../mysource_files/icon-tick.svg) no-repeat;
      }
    }

    //form

    .consultations__form {
      background: #efefef;
      padding: pxToRem(25);
    }

    .consultations__form-label {
      margin-top: pxToRem(40);

      background: #323232;
      display: inline-block;
      font-size: 0.875rem;
      padding: pxToRem(15) pxToRem(20);
      color: #fff;
      margin-bottom: pxToRem(36);
    }

    .consultations__form-footer-info {
      font-size: 0.875rem;
    }
  }

  &--in-progress {
    .consultations {
      &__container {

      }
      &__content {
        margin-top: 0;
      }
      &__countdown {
        font-size: pxToRem(14);

        strong {
          font-size: pxToRem(18);
        }
      }
      &__link {
        width: auto;
      }
      &__status {
        p {
          margin: 0;
        }
      }
      &__title {
        font-size: pxToRem(18);
      }
    }
  }
}
