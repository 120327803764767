/* Module: S */
.landscape__container {
  background-size: cover;

  &.inside {
    padding-top: pxToRem(22);
    padding-bottom: pxToRem(30);
    margin-bottom: pxToRem(10);
  }
}

.landscape__heading {
  font-size: pxToRem(41);
}

.tile__list {
  .tile__item:nth-child(-n + 8) {
    display: flex;
  }
}

.tile__item {
  flex-basis: 25%;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex-basis: calc(25% - 1rem);
  }

  &:nth-child(n + 3) {
    margin-top: 0;
  }

  &:nth-child(n + 5) {
    margin-top: 1rem;
  }
}
