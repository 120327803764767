/* Module: S */
.sq-form {
  .sq-form-question-title {
    float: none;
    width: 100%;
    font-size: 0.875rem;
  }

  .sq-form-question-answer,
  .sq-form-question-note,
  .sq-form-error {
    width: 100%;
    margin-left: 0;
    float: none;
    font-size: 0.875rem;
  }

  input[type="text"].sq-form-field,
  textarea.sq-form-field {
    width: 100%;
    max-width: 305px;
    font-size: 0.875rem;
  }
}
