/* Module: S */
.footer {
  margin-top: pxToRem(90);
  padding-top: 2rem;
  padding-bottom: 2rem;
  > .page__wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .nav__item {
    margin: 0 pxToRem(14);
  }
}

.nav__link {
  .nav-footer & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.social__container {
  margin-bottom: 0;
  order: inherit;
}

.social__list {
  display: flex;
}

.social__item {
  + .social__item {
    margin-left: pxToRem(12);
  }
}

.social__icon {
  height: pxToRem(24);
  width: pxToRem(24);
}
