/* Module: S */
.landscape__container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;


  &.homepage {
    &:before {
      background: rgba(28, 48, 74, 0.9);
    }
  }

  .page__wrapper {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // background: $theme-black;
  }

  &.homepage {
    background-color: #1c304a;
  }

  &.inside {
    background-color: #181818;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: pxToRem(24);
  }
}

//themes
// rnswdi-improvements
.landscape__container.banner--only{
  height: 82px !important;

  @media screen and (min-width: $desktop) {
    height: 213px !important;
  }

  @media (min-width: 900px) and (max-width: 959px) {
    height: 213px !important;
  }
}

.landscape__container.banner--only::before{
  background: none !important;
}

body.theme-grey{
  .landscape__container {
    &:before {
      background: rgba(0,0,0,.9);
    }
  }
}

body.theme-navy {
  .landscape__container {
    &:before {
      background: $theme-navy;
    }
  }
}

body.theme-plum {
  .landscape__container {
    &:before {
      background: $theme-plum;
    }
  }
}

body.theme-purple {
  .landscape__container {
    &:before {
      background: $theme-purple;
    }
  }
}

body.theme-green {
  .landscape__container {
    &:before {
      background: $theme-green;
    }
  }
}

.back-home__link {
  display: inline-block;
  font-size: 0.875rem;
  color: #fff;
  position: relative;
  text-decoration: none;
  margin-bottom: 0.8rem;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:before {
    content: '';
    display: inline-block;
    height: pxToRem(7);
    width: pxToRem(7);
    border-left: pxToRem(2) solid #fff;
    border-bottom: pxToRem(2) solid #fff;
    transform: rotate(45deg);
    vertical-align: middle;
    margin-right: pxToRem(6);
    margin-bottom: pxToRem(3);
  }
}

.landscape__heading {
  color: #fff;
  font-size: pxToRem(30);
  font-weight: bold;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;
}

.tile__container {
  margin-top: pxToRem(19);
}

.tile__list {
  @extend %list-reset;
  display: flex;
  flex-wrap: wrap;

  .tile__item:nth-child(n + 5) {
    display: none;
  }

  .show-all & {
    .tile__item:nth-child(n + 5) {
      display: flex;
    }
  }
}

.show-more__button {
  display: block;
  position: relative;
  color: #fff;
  font-size: pxToRem(18);
  font-weight: bold;
  background: transparent;
  padding: pxToRem(6) pxToRem(32);
  border: 0;
  margin: pxToRem(14) auto pxToRem(-14) auto;

  &:after {
    content: '';
    display: block;
    background-image: url({{file_dest}}/icon-arrow-down--white.svg);
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    height: pxToRem(40);
    width: pxToRem(20);
    right: 0;
    top: 1px;
  }

  .show-all & {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.tile__item {
  flex-basis: 100%;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
}

.tile__heading {
  font-size: pxToRem(17);
  line-height: normal;
  margin-top: 0;
  margin-bottom: 0;

  &:after {
    content: '';
    display: inline-block;
    margin-left: 0.35rem;
    background-image: url('{{file_dest}}/icon-arrow-right.svg');
    background-repeat: no-repeat;
    width: pxToRem(16);
    height: pxToRem(13);
    margin-top: -1px;
    vertical-align: middle;
  }
}

.tile__link {
  width: 100%;
  display: block;
  color: #fff;
  text-decoration: none;
  padding: pxToRem(15) 1.25rem;
  border: pxToRem(1) solid rgba(255, 255, 255, 0.5);

  &:hover,
  &:focus,
  &.active {
    color: #000;
    background-color: #fff;

    .tile__heading {
      color: $tomato-dark;
    }

    .tile__heading:after {
      background-image: url('{{file_dest}}/icon-arrow-right--orange.svg');
    }
  }
}

.tile__description {
  font-size: pxToRem(13);
  line-height: pxToRem(18);
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-weight: 100;
}

//rnswdi Design Improvements
.landscape__container.inside{
  background-image: url("../mysource_files/house-banner.png");
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  object-position: 50% 50%;
}

.landscape__heading{
  display: none;
}