/* Module: S */
.quick-links__container {
  padding-top: 4rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  flex-wrap: nowrap;
}

.quick-links__column {
  flex-basis: 25%;
  border-bottom: 0;
  padding: 0 1.5rem;

  &:first-child,
  &:nth-child(n + 3) {
    padding-top: 0;
  }

  &:nth-child(odd) {
    margin-right: 0;
  }
}

.quick-links__heading {
  min-height: pxToRem(93);
  margin-bottom: pxToRem(28);
}

.quick-links__heading--toggle {
  display: none;

  &:after {
    content: none;
  }
}

.quick-links__list {
  display: block;
}
