/* Module: S */
.header__actions {
    right: 2rem;
}

.nav__list {
    .nav-header & {
        margin-left: -2rem;
        margin-right: -2rem;
    }
}

.nav__link {
    .nav-header & {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}