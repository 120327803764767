/* Module: Matrix form */
// Global form styles
.sq-form-section,
.sq-form,
.sq-form fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

.sq-form-question {
    padding: .5em;
}

// Fields
.sq-form-field {
    border: 1px solid $matrix-form__border-colour;
}

.sq-form input[type="text"],
.sq-form input[type="password"],
.sq-form textarea {
    width: 100%;
    padding: .5em 0;
    @extend %border-box;
}

.sq-form textarea {
    padding: 0;
}

.lt-ie9 .sq-form input[type="text"],
.lt-ie9 .sq-form input[type="password"],
.lt-ie9 .sq-form textarea {
    width: 98%; // older IE doesn't handle border box properly
}

.sq-form input[type="text"]
.sq-form input[type="password"] {
    text-indent: .5em;
}

// Titles & Labels
.sq-form-question-title,
.sq-form-question-answer label {
    margin: .5em 0;
    font-weight: bold;
    display: block;
}

.sq-form-section-title {
    @include sq-font-size(1.2);
    font-weight: bold;
    border-top: 1px solid $matrix-form__border-colour;
    margin-top: 1em;
    display: block;
    padding-top: 1em;
    width: 100%;
    margin: .5em 0;
}

// Lists
.sq-form-question-tickbox-list ul,
.sq-form-question-option-list ul {
    @extend %list-reset;
}

.sq-form-question-tickbox-list li,
.sq-form-question-option-list li {
    clear: left;
    display: block;
    padding: .5em 0;
}

.sq-form input[type="radio"],
.sq-form input[type="checkbox"],
.sq-form input[type="radio"] ~ label,
.sq-form input[type="checkbox"] ~ label {
    float: left;
    font-weight: normal;
    margin: 0 0 0 .5em;
}

// Notes
.sq-form-question-note {
    font-size: 90%;
    font-style: italic;
    display: block;
    margin-bottom: .5em;
}

.sq-form-question {
    margin-bottom: 1em;
    @extend %clearfix;
}

// Date/Time
.sq-form .sq-form-question-datetime input[type="password"],
.sq-form .sq-form-question-datetime input[type="text"],
.sq-form .sq-form-question-datetime label,
.sq-form .sq-form-question-datetime select {
    @extend %inline-block;
    width: auto;
    margin-bottom: .5em;
}
.sq-form .sq-form-question-datetime label,
.sq-form .sq-form-question-datetime input {
    margin-right: .5em;
    font-weight: normal;
}

// Errors
.sq-form-error,
.sq-form-required-field {
    color: $matrix-form__error-text;
}

.sq-form-error {
    clear: left;
}

.sq-form-question-error {
    background-color: $matrix-form__error-bg;
}
