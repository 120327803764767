/* Module: S */
body:not(.home) {
  .footer {
    border-top: pxToRem(2) solid #181818;
  }
}

.footer {
  background-color: #f6f6f6;
  padding: pxToRem(40) 0 pxToRem(30);
  margin-top: pxToRem(60);

  > .page__wrapper {
    display: flex;
    flex-direction: column;
  }

  body.home & {
    margin-top: 0;
  }
}

.nav-footer {
  .nav__list {
    .nav__item {
      margin-left: 0;
    }
    &:first-child {
      border-bottom: 1px solid #ccc;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    &.bold {
      font-weight: 600;
    }

    .nav__link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.social__list {
  @extend %list-reset;
  display: inline-flex;
}

.social__item {
  + .social__item {
    margin-left: pxToRem(20);
  }
}

.social__icon {
  display: block;
  height: pxToRem(36);
  width: pxToRem(36);
}

.social__link {
  display: block;
}

.nav__link {
  .nav-footer & {
    display: block;
    padding-top: pxToRem(8);
    padding-bottom: pxToRem(8);
    font-size: pxToRem(14);
  }
}

.social__container {
  order: -1;
  margin-bottom: 1rem;
}
