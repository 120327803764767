/* Module: S */
.results__container {
  margin-top: -2rem;
}

.results__actions {
  .btn {
    width: auto;
  }
}
